import {useState} from 'react';
import {useUser} from '../helpers/auth-context';
import {MdOutlinePostAdd} from 'react-icons/md';
import axios, {AxiosResponse} from 'axios';
import PostCard from './PostCard';
import CreatepostPhotos from './CreatepostPhotos';
import {ProgressBar} from 'react-bootstrap';
import Progress from './global/Progress';
import {Community} from '@prisma/client';

export default function CreatePost({community}: {community?: Community}) {
  const [message, setMessage] = useState('');
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState('Uploading post');
  const [resultPost, setResultPost] = useState(false);
  const [confirmPhoto, setConfirmPhoto] = useState(false);

  async function post() {
    if (loading || message.length < 1) return;
    setLoading(true);
    const data = new FormData();
    setStep('Uploading post');
    if (photos.length > 0) {
      photos.forEach((file) => data.append('images', file));
    }
    data.set('message', message);
    if (community) {
      data.set('community', community.id);
    }
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/post/new`, data, {
      withCredentials: true,
      onUploadProgress: (progressEvent) => setProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100)),
    });

    const post = res.data;

    if ((post.images || []).length != 0) {
      setStep('Processing Image');
      setProgress(1);
      let imageRes: AxiosResponse<any, any>;
      while (imageRes == undefined) {
        imageRes = await axios.get(`${process.env.REACT_APP_API_URL}/image/${post.images[0].uid}/thumb.webp`, {withCredentials: true, validateStatus: (status) => true});
        if (imageRes.status != 200 || imageRes.data === '' || imageRes.headers['content-length'] === '0') {
          imageRes = undefined;
          // Clear it from the  local cache
          await caches.open('images').then((cache) => cache.delete(`${process.env.REACT_APP_API_URL}/image/${post.images[0].uid}/thumb.webp`));
          setProgress((c) => Math.min(c + 1, 83));
        }
      }
      setProgress(100);
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));

    setLoading(false);
    setProgress(0);
    setMessage('');
    setResultPost(res.data);
  }


  const {user} = useUser();

  const pictureUrl = user?.activeProfile?.profile.pictureUid ? `${process.env.REACT_APP_API_URL}/image/${user.activeProfile.profile.pictureUid}/thumb.webp` : `/assets/images/default-user.webp`;

  if (resultPost) {
    resultPost['likes'] = [];
    return <PostCard post={resultPost}/>;
  } else if (loading) {
    return <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
      <div className="typewriter ms-auto me-auto mt-4 mb-3">
        <div className="slide"><i></i></div>
        <div className="paper"></div>
        <div className="keyboard"></div>
      </div>
      <Progress completed={progress} step={step}/>
    </div>;
  } else {
    return (<>
      {confirmPhoto &&
                <div className="card card-error w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                  <div className="card-body p-0">
                    <a href="/" className="font-xssss fw-600 text-grey-900 card-body p-0 d-flex align-items-center"><i
                      className="btn-round-sm font-xs text-primary feather-alert-octagon me-2 bg-greylight"></i>This
                            is an IC (In Character) Social Media Platform!</a>
                  </div>
                  <div className="card-body p-0 mt-3 position-relative">
                    <span className="text-grey-700 text-dark-gray">Onrain is an In Character social media platform for the world of SchoolRP a part of Roleplay Hub, therefore it is highly discouraged to post OOC (Out Of Character / Real Life) photos of yourself or others. For everyone&apos;s safety please stick with in game screenshots and/or drawings.</span>
                  </div>
                </div>}
      <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
        <div className="card-body p-0">
          <a href="/" className="font-xssss fw-600 text-grey-900 card-body p-0 d-flex align-items-center"><i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>
            {community ?
              `Post On ${community.name}` :
                'Create Post'
            }
          </a>
        </div>
        <div className="card-body p-0 mt-3 position-relative">
          <figure className="avatar position-absolute ms-2 mt-1 top-5">
            <img src={pictureUrl}
              alt="icon"
              className="shadow-sm rounded-circle w30"/>
          </figure>
          <textarea name="message"
            className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
            cols={30} rows={10} placeholder= {community ?
              `Share something with ${community.name}` :
              'What\'s on your mind?'
            }
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            disabled={loading}
          ></textarea>
        </div>
        <div className="card-body d-flex p-0 mt-0">
          {/* <a href="#video"
                   className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i
                    className="font-md text-danger feather-video me-2"></i><span className="d-none-xs">Live Video</span></a> */}
          <input type="file" name="post-picture" id="createpost-photo"
            className="input-file" onChange={(e) => {
              setPhotos([e.target.files[0]]);
              setConfirmPhoto(true);
            }} accept="image/*"/>
          <label htmlFor="post-picture">
            <a href="/" onClick={(e) => {
              e.preventDefault();
              document.getElementById('createpost-photo').click();
            }}
            className="d-flex align-items-center font-xssss fw-600 ls-1 my-2 text-grey-700 text-dark pe-4"><i
                className="font-md text-icon-button feather-image me-2"></i><span
                className="d-none-xs">Add Photo</span></a>
          </label>
          {/* <a href="#activity"
                   className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i
                    className="font-md text-warning feather-camera me-2"></i><span
                    className="d-none-xs">Feeling/Activity</span></a>*/}

          <div
            className={`btn-round-md bg-lightgrey ms-auto ${(loading || message.length < 1) && 'is-disabled'}`}>
            <a href="/" onClick={(e) => {
              e.preventDefault();
              post();
            }}><MdOutlinePostAdd className="text-grey-900"
                size="30"/></a>
          </div>
        </div>
        {photos.length > 0 &&
                    <div className="card-body p-0"><CreatepostPhotos
                      photos={photos}
                      onRemove={(file) => setPhotos([])}
                    /></div>
        }
      </div>
    </>);
  }
}
