import * as React from 'react';
import {Fragment, ReactNode, useState} from 'react';
import useSWR from 'swr';
import axios from 'axios';
import Alert from '../components/Alert';

export type AlertProps = {
  title: string;
  content?: string;
  dismissible?: boolean;
  link?: string;
  linkText?: string;
  smooth?: boolean;
  onClose?: (v:void)=>void;
  onConfirm?: (v: React.MouseEvent<HTMLAnchorElement, MouseEvent>)=>void;
  childNode?: ReactNode;
}

const AlertContext = React.createContext<{ setAlert:(AlertProps) => void }>(undefined);


function AlertProvider(props: any) {
  const [alert, setAlert] = useState<AlertProps | undefined>();
  return (
    <>
      {alert !== undefined && <Alert onDismiss={() => setAlert(undefined)} {...alert}/>}
      <AlertContext.Provider value={{setAlert}} {...props} />
    </>
  );
}

const useAlert = () => React.useContext(AlertContext) as { setAlert:(props:AlertProps) => void };

export {AlertProvider, useAlert};

// the UserProvider in user-context.js is basically:
// const UserProvider = props => (
//   <UserContext.Provider value={useAuth().data.user} {...props} />
// )
// and the useUser hook is basically this:
// const useUser = () => React.useContext(UserContext)
