import {Link, useHistory, useParams} from 'react-router-dom';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import RequireUser from '../components/RequireUser';
import {useUser} from '../helpers/auth-context';
import PageAlert from '../components/PageAlert';
import Select from 'react-select';
import {useEffect, useState} from 'react';
import axios from 'axios';
import RightMessages from '../components/header/RightMessages';
import Button from '../components/Button';
import {OverlayTrigger, Popover, Tooltip} from 'react-bootstrap';
import NotAllowed from './NoPermission';
import {useCreateCampaignMutation, useGetAdvertiserAccountQuery, usePatchCampaignMutation} from '../store/ads/adsApi';
import Loading from '../components/Loading';
import {useForm} from 'react-hook-form';
import {AdvertismentCampaign, Event} from '@prisma/client';
import SearchPostResults from '../components/ads/SearchPostResults';
import PostCard from '../components/PostCard';
import {useCreateEventMutation} from '../store/events/eventsApi';

export default function CreateEvent() {
  const {user, reloadUser} = useUser();
  const [photo, setPhoto] = useState<File | null>(null);
  const {register, handleSubmit, setValue} = useForm<Omit<Event, 'id' | 'profileId' | 'imageUid'>>({defaultValues: {}});
  const history = useHistory();

  const profile = user.activeProfile?.profile;
  const canCreateEvent = profile && (profile.verified || profile.businessProfile?.verified);

  if (!canCreateEvent) {
    return <NotAllowed/>;
  }

  function save(data: Omit<Event, 'id' | 'profileId' | 'imageUid'>) {
    // make the request using axios

    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('location', data.location);
    formData.append('description', data.description);
    formData.append('startDate', data.startDate.toISOString());
    formData.append('endDate', data.endDate.toISOString());
    if (photo) formData.append('images', photo);

    axios.post(`${process.env.REACT_APP_API_URL}/event/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
    }).then(() => {
      history.push('/events');
    });
  }

  return (
    <RequireUser>
      <Header/>
      <Leftnav/>
      <RightMessages />

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active">

        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                  <Link to={'/advertiser'} className="d-inline-block mt-2"><i
                    className="ti-arrow-left font-sm text-white"></i></Link>
                  <h4 className=" font-xs text-white fw-600 ms-4 mb-0 mt-2">New Event</h4>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">

                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">Event Name</label>
                        <input type="text" className="form-control" {...register('name', {required: true})}/>
                      </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">Location</label>
                        <input type="text" className="form-control" {...register('location', {required: true})}/>
                      </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">Start Date</label>
                        <input type="datetime-local" className="form-control" {...register('startDate', {valueAsDate: true})}/>
                      </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">End Date</label>
                        <input type="datetime-local" className="form-control" {...register('endDate', {valueAsDate: true})}/>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Description</label>
                          <textarea style={{height: 100}} className="form-control" {...register('description', {required: true})}/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="card mt-3 border-0">
                        <div
                          className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                            <label
                              className="mont-font fw-600 font-xsss mb-2 text-dark">Banner
                              Picture</label>
                            <input type="file" name="banner-picture" id="banner-picture"
                              className="input-file" accept="image/png, image/jpeg, image/webp" onChange={(e) => {
                                setPhoto(e.target.files[0]);
                              }}/>
                            <label htmlFor="banner-picture"
                              className={`rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed ${false && 'is-invalid '}`}>
                              <i className={`${photo ? 'ti-file' : 'ti-cloud-down'} large-icon me-3 d-block`}></i>
                              <span
                                className="js-fileName">{photo ? photo.name : `Click to upload a event photo!`}</span>
                            </label>
                            <div className="invalid-feedback text-red font-xsss fw-500 ">

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <Button onClick={handleSubmit(save)} loading={false}
                        className="p-3">Create</Button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <Popupchat/>
      <Appfooter/>
    </RequireUser>
  );
}
