import {Rating, RoundedStar} from '@smastrom/react-rating';


export default function BusinessDetail({business}: {business: any}) {
  console.log(business);
  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-block p-4">
        <h4 className="fw-700 mb-3 font-xsss text-grey-900">Reviews</h4>
        <Rating value={business._avg.rating} readOnly halfFillMode={'svg'} itemStyles={{
          itemShapes: RoundedStar,
          activeFillColor: '#f6bc03',
          inactiveFillColor: 'rgba(215,215,215)',
        }}/>
      </div>
      <div className="card-body d-flex pt-0">
        <i className="feather-star text-grey-500 me-3 font-lg"></i>
        <h4 className="fw-700 text-grey-900 font-xssss mt-1">{business._count._all} rating{business._count._all !== 1 ? 's' : ''}</h4>
      </div>
    </div>
  );
}
