import {Link} from 'react-router-dom';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import RequireUser from '../components/RequireUser';
import MessageMemberRow from '../components/messages/MessageMemberRow';
import Loading from '../components/Loading';
import NotFound from './Notfound';
import {useUser} from '../helpers/auth-context';
import axios from 'axios';
import useSWR from 'swr';
import {useParams, useHistory} from 'react-router-dom';
import {ProfileInfo} from '../api-types';
import Select, {ActionMeta, OnChangeValue, StylesConfig} from 'react-select';
import {useEffect, useState} from 'react';
import CreatableSelect from 'react-select/creatable';
import Button from '../components/Button';
import RightMessages from '../components/header/RightMessages';
import {AlertProps, useAlert} from '../helpers/alert-provider';
import GroupInvitesTable from '../components/messages/GroupInvitesTable';
import {MessageGroupMember} from '@prisma/client';

type Selection = ({isFixed?: boolean; value: string; label: string});

export default function MessagesOptions() {
  const {user} = useUser();
  const {groupId} = useParams() as any;
  const history = useHistory();
  const [selected, setSelected] = useState<Selection[]>([]);
  const [creating, setCreating] = useState<boolean>(false);
  const [creatingGroup, setCreatingGroup] = useState<boolean>(false);
  const [options, setOptions] = useState<Selection[]>(user?.activeProfile?.profile.following
      .map((obj) => {
        return {value: obj.tag, label: `@${obj.tag}`};
      }));
  const [addOpen, setAddOpen] = useState(false);
  const toggleAddOpen = () => setAddOpen(!addOpen);
  const [name, setName] = useState(undefined);
  const [desc, setDesc] = useState(undefined);
  const [image, setImage] = useState(undefined);
  const [patching, setPatching] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [leaving, setLeaving] = useState(false);
  const {setAlert} = useAlert();

  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };
  const {data, mutate} = useSWR(`${process.env.REACT_APP_API_URL}/messages/group/${groupId}`, fetcher);

  useEffect(() => {
    if (!data) return;
    const group = data.data;
    if (group.type === 'PERSONAL' && selected.length === 0) {
      const otherMembership: MessageGroupMember & ProfileInfo = group.members.find((member) => member.profileId !== user.activeProfile?.profileId);
      setSelected([{value: otherMembership.profile.tag, label: `@${otherMembership.profile.tag}`, isFixed: true}]);
    }
    if (group.type !== 'PERSONAL') {
      setOptions(options.filter((option) => !group.members.some((member) => member.profile.tag == option.value)));
    }

    if (name === undefined) setName(group.name);
    if (desc === undefined) setDesc(group.description);
    setImage(group.imageUid);
  }, [data]);

  if (!user) {
    return <RequireUser> </RequireUser>;
  }

  if (!data) {
    return <Loading/>;
  }

  if (data.status === 404) {
    return <NotFound/>;
  }

  const group = data.data;
  const isPersonal = group.type === 'PERSONAL';


  const userMembership: MessageGroupMember = group.members.find((member) => member.profileId === user.activeProfile?.profileId);

  const onChange = (value: OnChangeValue<Selection, true>,
      actionMeta: ActionMeta<Selection>) =>{
    switch (actionMeta.action) {
      case 'pop-value':
      case 'remove-value':
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        value = selected.filter((v) => v.isFixed);
        break;
    }
    // @ts-ignore
    setSelected(value);
  };

  const onCreateOption = async (inputValue: string) => {
    if (creating || inputValue.toLowerCase() === user?.activeProfile?.profile.tag) return;
    if (group.type != 'PERSONAL' && group.members.some((member) => member.profile.tag.toLowerCase() == inputValue.toLowerCase())) return;
    setCreating(true);
    const data = await axios.get(`${process.env.REACT_APP_API_URL}/profile/${inputValue}`, {validateStatus: () => true});
    if (data.data.success === true) {
      setSelected(selected.concat({value: inputValue, label: `@${inputValue}`}));
    }
    setCreating(false);
  };

  const createGroup = async () => {
    if (creatingGroup || selected.length < 2) return;
    setCreatingGroup(true);
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/messages/group/open`, {type: 'GROUP', profiles: selected.map((selection) => selection.value)}, {validateStatus: () => true, withCredentials: true});
    if (res.status === 200) {
      history.push(`/messages/${res.data.groupId}`);
    } else {
      setCreatingGroup(false);
    }
  };

  const addMembers = async () => {
    if (creatingGroup || selected.length < 1) return;
    setCreatingGroup(true);
    const res = await axios.put(`${process.env.REACT_APP_API_URL}/messages/group/${groupId}/members`, {profiles: selected.map((selection) => selection.value)}, {
      validateStatus: () => true,
      withCredentials: true,
    });
    if (res.status === 200) {
      setAddOpen(false);
      await mutate();
    }
    setCreatingGroup(false);
  };

  function updateGroupImage(imageBlob, filename) {
    if (uploading) return;
    const formData = new FormData();
    formData.set('image', imageBlob, filename);
    setUploading(true);
    axios.patch(`${process.env.REACT_APP_API_URL}/messages/group/${groupId}/image`, formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((res) => {
      setImage(res.data);
      setUploading(false);
    });
  }

  const styles: StylesConfig<Selection, true> = {
    multiValue: (base, state) => {
      return state.data.isFixed ? {...base, backgroundColor: 'gray'} : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed ?
          {...base, fontWeight: 'bold', color: 'white', paddingRight: 6} :
          base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? {...base, display: 'none'} : base;
    },
  };

  function leave() {
    const alert: AlertProps = {
      title: 'Are you sure you want to leave this group?',
      content: `You are about to leave ${group.name}. Are you sure you want to do this? To enter this group again an admin needs to invite you again.`,
      dismissible: false,
      onConfirm: () => {
        setLeaving(true);
        axios.post(`${process.env.REACT_APP_API_URL}/messages/group/${groupId}/leave`, {}, {withCredentials: true}).then((res) => {
          window.location.href = '/';
        });
      },
    };

    setAlert(alert);
  }

  return (
    <RequireUser>
      <Header/>
      <Leftnav/>
      <RightMessages/>

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active">

        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                  <Link to={`/messages/${groupId}`} className="d-inline-block mt-2"><i
                    className="ti-arrow-left font-sm text-white"></i></Link>
                  <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Group Options</h4>
                  {group.type == 'GROUP' && userMembership.role !== 'OWNER' && <Button className="ms-auto d-block bg-danger" type="submit" onClick={leave}><b>Leave Group</b></Button>}
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    if (patching) return;
                    setPatching(true);
                    axios.patch(`${process.env.REACT_APP_API_URL}/messages/group/${groupId}`, {name: name, desc: desc}, {withCredentials: true, validateStatus: () => true})
                        .then((res) => {
                          setPatching(false);
                        });
                  }}>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                                                        Group Name
                          </label>
                          <input type="text"
                            maxLength={120}
                            className={`form-control`}
                            defaultValue={group.name}
                            disabled={isPersonal || userMembership.role === 'MEMBER'}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          <div className="invalid-feedback text-red font-xsss fw-500 ">

                          </div>
                        </div>
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            Group Description
                          </label>
                          <textarea
                            style={{height: 100}}
                            maxLength={1000}
                            className={`form-control scroll-bar lh-20`}
                            defaultValue={group.description}
                            disabled={isPersonal || userMembership.role === 'MEMBER'}
                            value={desc}
                            onChange={(e) => setDesc(e.target.value)}
                          />
                          <div className="invalid-feedback text-red font-xsss fw-500 ">

                          </div>
                        </div>
                        <Button className="ms-auto d-block" disabled={patching || isPersonal || userMembership.role === 'MEMBER'} loading={patching} type="submit">Update </Button>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                                                        Group Image (Click to change)
                          </label> <br/>
                          <input type="file" name="profile-picture" id="profile-picture"
                            className="input-file" accept="image/*"
                            disabled={isPersonal || userMembership.role === 'MEMBER'}
                            onChange={(e) => {
                              updateGroupImage(e.target.files[0], e.target.files[0].name);
                            }}
                          />
                          <label htmlFor="profile-picture" className="cursor-pointer">
                            <figure
                              className={`d-block avatar ms-auto me-auto mb-0 ${uploading && 'img-loading'}`}
                              style={{
                                position: 'relative',
                                height: '210px',
                                width: '210px',
                              }}>
                              <div className="loader"/>
                              <img
                                src={`${process.env.REACT_APP_API_URL}/image/${image}/thumb`}
                                alt="avatar"
                                className="shadow-sm rounded-3 w-100"/>
                            </figure>
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>
                  {!isPersonal && (userMembership.role == 'ADMIN' || userMembership.role == 'OWNER') && <GroupInvitesTable groupId={groupId} />}
                  <div className="row form-group">
                    <div className="flex-row d-flex position-relative">
                      <label className="d-block mont-font fw-600 font-xsss mb-2">
                        <span>Group Members</span>
                      </label>
                      {(group.type === 'PERSONAL' || userMembership.role == 'ADMIN' || userMembership.role == 'OWNER') && <><a className="ms-auto d-block fw-600 cursor-pointer"
                        onClick={toggleAddOpen}>{group.type === 'PERSONAL' ? 'Create Group' : 'Add Members'}</a>
                      <div className={`dropdown-menu p-3 right-0 rounded-xxl border-0 shadow-lg ${addOpen ? 'show' : ''}`} style={{width: 500}}>
                        <div className="form-group">
                          <label className="ms-auto mont-font fw-600 font-xsss mb-2">
                        Select Group Members
                          </label>
                          <CreatableSelect
                            isMulti
                            value={selected}
                            isClearable={selected.some((v) => !v.isFixed)}
                            styles={styles}
                            onChange={onChange}
                            onCreateOption={onCreateOption}
                            className="react-select react-fix"
                            classNamePrefix="react-select"
                            formatCreateLabel={(e) => `Add '@${e}'`}
                            noOptionsMessage={(e) => 'Type to add someone else'}
                            isLoading={creating}
                            isDisabled={creating}
                            options={options}
                          />
                        </div>
                        <div className="mt-2">
                          <Button className="w-100 d-block"
                            disabled={(selected.length < 2 && group.type === 'PERSONAL') || selected.length < 1 || creatingGroup}
                            loading={creatingGroup}
                            onClick={group.type === 'PERSONAL' ? createGroup : addMembers}>
                            {group.type === 'PERSONAL' ? 'Create Group' : 'Add Members'}
                          </Button>
                        </div>
                      </div></>}
                    </div>

                    <div className="scroll-bar" style={{maxHeight: 1000}}>
                      <ul className="profile-list" style={{paddingBottom: 90}}>
                        {group.members.map((member) => <MessageMemberRow key={member.profileId}
                          member={member}
                          role={userMembership.role} groupId={groupId} onUpdate={mutate}/>)}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <Popupchat/>
      <Appfooter/>
    </RequireUser>
  );
}
