import React, {Component, useCallback} from 'react';
import ReactDOM from 'react-dom/client';


import './main.scss';
import '@smastrom/react-rating/style.css';
import {Provider} from 'react-redux';


import Home from './pages/Home';


import Login from './pages/Login';
import Logout from './pages/Logout';
import Register from './pages/Register';
import Notfound from './pages/Notfound';

import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {UserProvider} from './helpers/auth-context';
import CreateProfile from './pages/CreateProfile';
import EditProfile from './pages/EditProfile';
import ProfilePage from './pages/ProfilePage';
import ProfileList from './pages/ProfileList';
import ProfileRequests from './pages/ProfileRequests';
import Notifications from './pages/Notifications';
import Messages from './pages/Messages';
import MessagesList from './pages/MessagesList';
import MessagesOptions from './pages/MessagesOptions';
import PostPage from './pages/PostPage';
import LinkAccountPage from './pages/LinkAccountPage';
import Forgot from './pages/Forgot';
import {AlertProvider} from './helpers/alert-provider';
import Tag from './pages/Tag';
import ProfileMembers from './pages/ProfileMembers';
import FollowerList from './pages/FollowerList';
import FollowingList from './pages/FollowingList';
import MessageInvite from './pages/MessageInvite';
import AccountList from './pages/AccountList';
import Settings from './pages/Settings';
import ExternalAccounts from './pages/ExternalAccounts';
import DiscordLinkConfirm from './pages/DiscordLinkConfirm';
import RegisterDiscord from './pages/RegisterDiscord';
import PendingVerifications from './pages/admin/PendingVerifications';
import ProfileBadge from './components/global/ProfileBadge';
import BadgeList from './pages/BadgeList';
import Advertiser from './pages/Advertiser';
import {store} from './store';
import EditCampaign from './pages/EditCampaign';
import AdAdmin from './pages/AdAdmin';
import Events from './pages/Events';
import CreateEvent from './pages/CreateEvent';
import HypeTeams from './pages/HypeTeams';
import Communities from './pages/Communities';
import CreateCommunity from './pages/CreateCommunity';
import CommunityPage from './pages/CommunityPage';
import CommunityRequests from './pages/CommunityRequests';
import JoinedCommunities from './pages/JoinedCommunityPage';
import ModeratingCommunities from './pages/ModeratingCommunityPage';
import EditCommunity from './pages/EditCommunity';
import CommunityMembers from './pages/CommunityMembers';

const redirect = (path) => {
  return () => {
    window.location.replace(path);
    return <></>;
  };
};

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={'/'}>
        <Switch>
          <Route exact path={`/`} component={Home}/>


          <Route exact path={`/login`} component={Login}/>
          <Route exact path={`/register`} component={Register}/>
          <Route exact path={`/forgot`} component={Forgot}/>
          <Route exact path={`/logout`} component={Logout}/>

          <Route exact path={`/register/discord`} component={RegisterDiscord}/>


          <Route exact path={`/profile/create`} component={CreateProfile}/>
          <Route exact path={`/profile/edit`} component={EditProfile}/>
          <Route exact path={`/profile/edit/members`} component={ProfileMembers}/>
          <Route exact path={`/profile/requests`} component={ProfileRequests}/>
          <Route exact path={`/profile/list`} component={ProfileList}/>
          <Route exact path={`/p/:tag`} component={ProfilePage} />
          <Route exact path={`/p/:tag/followers`} component={FollowerList} />
          <Route exact path={`/p/:tag/following`} component={FollowingList} />
          <Route exact path={`/p/:tag/badges`} component={BadgeList} />
          <Route exact path={`/p/:tag/:category`} component={ProfilePage} />


          <Route exact path={`/p/:tag/post/:post`} component={PostPage} />
          <Route exact path={`/tag/:tag`} component={Tag} />
          <Route exact path={`/notifications`} component={Notifications} />
          <Route exact path={`/messages`} component={MessagesList} />
          <Route exact path={`/messages/invite/:slug`} component={MessageInvite} />
          <Route exact path={`/messages/:groupId`} component={Messages} />
          <Route exact path={`/messages/:groupId/options`} component={MessagesOptions} />

          <Route exact path={`/settings/accounts/link`} component={LinkAccountPage}/>
          <Route exact path={`/settings/accounts`} component={AccountList}/>
          <Route exact path={`/settings/linked`} component={ExternalAccounts}/>
          <Route exact path={`/settings/linked/discord`} component={DiscordLinkConfirm}/>
          <Route exact path={`/settings`} component={Settings}/>

          <Route exact path={`/discord-bot`} component={redirect('https://discord.com/api/oauth2/authorize?client_id=1085204714049765426&permissions=0&scope=bot')} />

          <Route exact path={`/admin/verifications`} component={PendingVerifications} />
          <Route exact path={`/admin/ads`} component={AdAdmin} />

          <Route exact path={'/ads'} component={redirect('/advertiser')} />
          <Route exact path={`/advertiser`} component={Advertiser} />
          <Route exact path={`/advertiser/campaign/edit`} component={EditCampaign} />
          <Route exact path={`/advertiser/campaign/edit/:campaignId`} component={EditCampaign} />
          <Route exact path={`/events`} component={Events}/>
          <Route exact path={`/events/create`} component={CreateEvent}/>
          <Route exact path={`/events/hypeteams`} component={HypeTeams}/>

          <Route exact path={'/communities'} component={Communities} />
          <Route exact path={'/communities/joined'} component={JoinedCommunities} />
          <Route exact path={'/communities/moderating'} component={ModeratingCommunities} />
          <Route exact path={'/community/create'} component={CreateCommunity} />
          <Route exact path={'/c/:tag'} component={CommunityPage} />
          <Route exact path={'/c/:tag/edit'} component={EditCommunity} />
          <Route exact path={'/c/:tag/requests'} component={CommunityRequests} />
          <Route exact path={'/c/:tag/members'} component={CommunityMembers} />

          {/* <Route exact path={`/admin`} component={Email}/>*/}

          {/* <Route exact path={`/grouppage`} component={Grouppage}/>*/}
          {/* <Route exact path={`/defaultgroup`} component={Group}/>*/}
          {/* <Route exact path={`/defaultvideo`} component={Videos}/>*/}


          {/*
          <Route exact path={`/defaultbadge`} component={Badge}/>
          <Route exact path={`/defaultgroup`} component={Group}/>
          <Route exact path={`/defaultstorie`} component={Storie}/>
          <Route exact path={`/defaultemailbox`} component={Email}/>
          <Route exact path={`/defaultemailopen`} component={Emailopen}/>
          <Route exact path={`/defaultsettings`} component={Settings}/>
          <Route exact path={`/defaultvideo`} component={Videos}/>
          <Route exact path={`/defaultanalytics`} component={Analytics}/>


          <Route exact path={`/accountinformation`} component={Account}/>
          <Route exact path={`/defaultmember`} component={Member}/>
          <Route exact path={`/contactinformation`} component={Contactinfo}/>
          <Route exact path={`/socialaccount`} component={Socialaccount}/>
          <Route exact path={`/password`} component={Password}/>
          <Route exact path={`/payment`} component={Payment}/>
          <Route exact path={`/defaultnoti`} component={Notification}/>
          <Route exact path={`/helpbox`} component={Helpbox}/>
          <Route exact path={`/shop1`} component={ShopOne}/>
          <Route exact path={`/shop2`} component={ShopTwo}/>
          <Route exact path={`/shop3`} component={ShopThree}/>
          <Route exact path={`/singleproduct`} component={Singleproduct}/>
          <Route exact path={`/cart`} component={Cart}/>
          <Route exact path={`/checkout`} component={Checkout}/>
          <Route exact path={`/defaultmessage`} component={Chat}/>
          <Route exact path={`/defaultlive`} component={Live}/>

          <Route exact path={`/defaultjob`} component={Job}/>
          <Route exact path={`/defaultevent`} component={Event}/>
          <Route exact path={`/defaulthotel`} component={Hotel}/>
          <Route exact path={`/grouppage`} component={Grouppage}/>
          <Route exact path={`/userpage`} component={Userpage}/>
          <Route exact path={`/authorpage`} component={Authorpage}/>
          <Route exact path={`/comingsoon`} component={Comingsoon}/>
          <Route exact path={`/defaulthoteldetails`} component={Hotelsingle}/>*/}

          <Route path="*" component={Notfound}/>
        </Switch>
      </BrowserRouter>
    );
  }
}

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(<Provider store={store}><UserProvider><AlertProvider><Root /></AlertProvider></UserProvider></Provider>);

// ReactDOM.render(
//     <UserProvider><AlertProvider><Root /></AlertProvider></UserProvider>
//     , document.getElementById('root'));
// serviceWorker.register();
