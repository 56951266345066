import {BsPatchCheckFill} from 'react-icons/bs';
import {Link} from 'react-router-dom';
import {IoMdBusiness} from 'react-icons/io';

export default function PostCardComment({comment, post}) {
  const isOP = comment.postedBy.tag === post.postedBy.tag;
  console.log(comment.postedBy);

  return <div className="card-body p-0 mt-3 position-relative w-100">
    {isOP ? <>
      <div
        className={`bor-0 rounded-xxl p-2 ms-auto me-5 font-xssss text-grey-900 fw-500 border-light-md theme-dark-bg post-comment isOp`}>
        <Link to={`/p/${comment.postedBy.tag}`} className="fw-700 text-dark-white text-grey-900">{comment.postedBy.name} </Link> <Link
          to={`/p/${comment.postedBy.tag}`} className="text-grey-700">@{comment.postedBy.tag}
          {comment.postedBy.businessProfile?.verified === false && <span title="This is an unverified business profile" className="profile-badge ms-1 font-sm text-dark-white text-grey-900" style={{lineHeight: '0'}}><IoMdBusiness/></span>}
          {comment.postedBy.businessProfile?.verified === true && <span title="This is a verified store or business within Karakura and can be trusted" className="profile-badge ms-1 text-theme font-sm" style={{lineHeight: '0'}}><IoMdBusiness/></span>}
          {comment.postedBy.verified && <span title="The identity of this account is verified" className="profile-badge ms-1 text-theme font-sm" style={{lineHeight: 0}}><BsPatchCheckFill/></span>}
        </Link> <br/>
        <p>{comment.message}</p>
      </div>
      <figure className="avatar position-absolute ms-2 mt-1 top-5 end-0">
        <img src={`${process.env.REACT_APP_API_URL}/image/${comment.postedBy.pictureUid}/thumb.webp`}
          alt="icon"
          className="shadow-sm rounded-circle w30"/>
      </figure>
    </> :
            <>
              <figure className="avatar position-absolute ms-2 mt-1 top-5">
                <img src={`${process.env.REACT_APP_API_URL}/image/${comment.postedBy.pictureUid}/thumb.webp`}
                  alt="icon"
                  className="shadow-sm rounded-circle w30"/>
              </figure>
              <div
                className={`bor-0 rounded-xxl p-2 ms-5 font-xssss text-grey-900 fw-500 border-light-md theme-dark-bg post-comment`}>
                <Link to={`/p/${comment.postedBy.tag}`} className="fw-700 text-dark-white text-grey-900">{comment.postedBy.name}
                </Link> <Link to={`/p/${comment.postedBy.tag}`}
                  className="text-grey-700">@{comment.postedBy.tag}
                  {comment.postedBy.businessProfile?.verified === false && <span title="This is an unverified business profile" className="profile-badge ms-1 font-sm text-dark-white text-grey-900" style={{lineHeight: '0'}}><IoMdBusiness/></span>}
                  {comment.postedBy.businessProfile?.verified === true && <span title="This is a verified store or business within Karakura and can be trusted" className="profile-badge ms-1 text-theme font-sm" style={{lineHeight: '0'}}><IoMdBusiness/></span>}
                  {comment.postedBy.verified && <span title="The identity of this account is verified" className="profile-badge ms-1 text-theme font-sm" style={{lineHeight: 0}}><BsPatchCheckFill/></span>}
                </Link> <br/>
                <p>{comment.message}</p>
              </div>
            </>}

  </div>;
}
