import {Fragment, useState} from 'react';
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Pagetitle from '../components/Pagetitle';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import {BsFillCloudHazeFill} from 'react-icons/bs';
import {Link} from 'react-router-dom';
import FollowRequestButtons from '../components/FollowRequestButtons';
import {useUser} from '../helpers/auth-context';
import RequireUser from '../components/RequireUser';
import axios from 'axios';
import useSWR from 'swr';
import Loading from '../components/Loading';
import {useParams} from 'react-router-dom';
import NotAllowed from './NoPermission';
import RightMessages from '../components/header/RightMessages';
import {Icon} from '@iconify/react';
import Button from '../components/Button';


export default function BadgeList() {
  const {user, reloadUser} = useUser();
  const {tag} = useParams<{tag: string}>();
  const [loading, setLoading] = useState<string>(undefined);
  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };
  const {data, mutate} = useSWR(`${process.env.REACT_APP_API_URL}/profile/${tag}`, fetcher);
  if (!data) return <Loading/>;
  if (data.status != 200) return <NotAllowed/>;

  const badges = data.data.profile.badges;
  const isOwn = user?.activeProfile.profileId === data.data.profile.id;

  function activateBadge(badgeId: string) {
    if (loading) return;
    setLoading(badgeId);
    axios.post(`${process.env.REACT_APP_API_URL}/profile/activebadge`, {badgeId}, {withCredentials: true})
        .then(async (res) => {
          reloadUser();
          return mutate();
        })
        .then(() => setLoading(undefined))
        .catch((err) => {
          setLoading(undefined);
        });
  }


  return (
    <Fragment>
      <Header/>
      <Leftnav/>
      <RightMessages/>

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <Pagetitle title={`Earned Badges @${tag}`}/>

                <div className="row ps-2 pe-2">
                  {badges?.length > 0 ? badges.map((badge, index) => (
                    <div key={index} className="col-md-4 col-sm-6 col-12 pe-2 ps-2 text-center">
                      <div
                        className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                        <div className={`card-body d-block w-100 ps-3 pe-3  ${isOwn ? 'pb-0' : 'pb-4'} text-center`}>
                          <Icon width={52} height={52} color={badge.badge.color} icon={badge.badge.icon}/>
                          <div className="clearfix w-100"></div>
                          <h4 className="fw-700 font-xsss mt-3 mb-0">{badge.badge.name} </h4>
                          <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">{badge.badge.description}</p>
                        </div>
                        {isOwn && <Button
                          onClick={() => activateBadge(badge.badgeId)}
                          className={'mb-4'}
                          loadingSize={16}
                          disabled={loading == badge.badgeId || user.activeProfile.profileId == badge.activeId}
                          loading={loading == badge.badgeId}
                          // spanClassName={acceptSpanClassName}
                        >{user.activeProfile.profileId == badge.activeId ? 'Active' : 'Activate'}</Button>}
                      </div>
                    </div>
                  )) : <div className="col-md-12 col-sm-12 pe-2 ps-2">
                    <div
                      className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                      <div
                        className="card-body d-block w-100 ps-3 pe-3 pb-0 text-center text-grey-500">
                        <BsFillCloudHazeFill className="" size={52}/>
                        <div className="clearfix w-100"></div>
                        <p>@{tag} doesn&#39;t have any badges yet...</p>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Appfooter/>

    </Fragment>
  );
}
