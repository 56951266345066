import {useEffect, useState} from 'react';

export default function Progress({completed, step}:{completed: number, step: string}) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(completed);
  }, [completed]);

  return (
    <div className="progress-container">
      <div className="progress-bar">
        <div className="completed-bar" style={{width: progress+'%', opacity: 1}}>
        </div>
      </div>
      <div className="progress-information">
        <p>{step}</p>
        <p>{completed}%</p>
      </div>
    </div>
  );
}
