import FollowRequestButtons from '../FollowRequestButtons';
import {useUser} from '../../helpers/auth-context';
import {Link} from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

export default function FollowRequests(): JSX.Element {
  const {user} = useUser();

  if (!user) return <></>;
  const profile = user.activeProfile?.profile;
  if (!profile) return <></>;

  if (profile.incomingRequests.length === 0) return <></>;

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-flex align-items-center p-4">
        <h4 className="fw-700 mb-0 font-xssss text-grey-900">Friend Request</h4>
        <Link to="/profile/requests" className="fw-600 ms-auto font-xssss text-primary">See all</Link>
      </div>
      {profile.incomingRequests.slice(0, Math.min(3, profile.incomingRequests.length)).map((request, index) => (
        <div className="wrap" key={index}>
          <div className="card-body d-flex pt-0 ps-4 pe-4 pb-0 bor-0">
            <figure className="avatar me-3"><img src={`${API_URL}/image/${request.pictureUid}/thumb.webp`} alt="avater" className="shadow-sm rounded-circle w45" /></figure>
            <h4 className="fw-700 text-grey-900 font-xssss mt-1">{request.name} <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">@{request.tag}</span></h4>
          </div>
          <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4">
            <FollowRequestButtons
              tag={request.tag}
              acceptClassName="p-2 lh-20 w100 bg-primary me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl"
              ignoreClassName="p-2 lh-20 w100 bg-grey text-center font-xssss fw-600 ls-1 rounded-xl"
              ignoreSpanClassName="text-grey-800"/>
          </div>
        </div>
      ))}
    </div>
  );
}

