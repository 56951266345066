import {Button} from 'react-bootstrap';
import {ImCross} from 'react-icons/im';

type CreatepostPhotosProps = {
    photos: File[];
    onRemove?: (file: File) => void;
}

export default function CreatepostPhotos({
  photos,
  onRemove = () => {
  },
}: CreatepostPhotosProps) {
  return (
    <>
      {photos.map((file, index) => <>
        <div key={index} className="position-relative photos-container">
          <img src={URL.createObjectURL(file)} className="photo" alt="uploaded photo"/>
          <Button className="position-absolute remove-button"
            onClick={() => onRemove(file)}
          ><ImCross className="remove-button-icon"/></Button>
        </div>
      </>)}
    </>
  );
}
