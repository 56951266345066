import React, {Component, Fragment} from 'react';
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';

import GoogleMapReact from 'google-map-react';
import RightMessages from '../components/header/RightMessages';
import {Icon} from '@iconify/react';
import Button from '../components/Button';
import {useUser} from '../helpers/auth-context';
import {Link} from 'react-router-dom';
import useSWR from 'swr';
import {fetcher} from '../helpers/utils';
import {Profile, Event} from '@prisma/client';
import EventCard from '../components/events/EventCard';

export default function Events() {
  const {user} = useUser();
  const profile = user?.activeProfile?.profile;
  const canCreateEvent = profile && (profile.verified || profile.businessProfile?.verified);
  const {data: events} = useSWR<(Event & {profile: Profile})[]>(`${process.env.REACT_APP_API_URL}/events/upcoming`, fetcher);

  return (
    <Fragment>
      <Header/>
      <Leftnav/>
      <RightMessages/>

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-12 flex-row d-flex">
                <h4 className="font-xl fw-700">Upcoming Events</h4>
                {canCreateEvent && <Link to={'/events/create'} className="ms-auto"><Button>Create Event</Button></Link>}
              </div>
              {(events || []).map((event) => <EventCard event={event} key={event.id}/>)}
            </div>
          </div>
        </div>
      </div>

      <Popupchat/>
      <Appfooter/>

    </Fragment>
  );
}
