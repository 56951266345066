import {Fragment, useRef, useState} from 'react';
import axios from 'axios';


export default function Forgot() {
  const [codeSent, setCodeSent] = useState(false);
  const [password, setPassword] = useState('');
  const [errorPasswordNotMatch, setErrorPasswordNotMatch] = useState(false);
  const [username, setUsername] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [tokenError, setTokenError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [done, setDone] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const phonenumberRef = useRef<HTMLInputElement>(null);
  const codeRef = useRef<HTMLInputElement>(null);
  const passwordError = errorPasswordNotMatch || validationError;

  function submit(e) {
    console.log('submitting');
    e.preventDefault();
    if (codeSent) {
      console.log(codeSent);
      const passwordError = !(password.length > 6) ||
      !/[a-z]/.test(password) ||
      !/[A-Z]/.test(password) ||
      username.toLowerCase() == password.toLowerCase();
      const passwordNotMatch = password !== repeatPassword;
      setErrorPasswordNotMatch(passwordNotMatch);
      setValidationError(passwordError);
      if (passwordNotMatch || passwordError) return;
    }
    console.log('passed', loading);
    if (loading) return;
    setLoading(true);
    console.log('lets load');
    axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot`, !codeSent ? {action: 'SUBMIT', phonenumber: phonenumberRef.current!.value} :
        {action: 'VERIFY', username: username, password: password, code: codeRef.current!.value}, {validateStatus: () => true, withCredentials: true})
        .then((res) => {
          if (!codeSent) {
            if (res.status == 429) {
              setError('Too many requests, please wait before requesting another reset.');
            } else if (res.status == 200) {
              if (res.data.type === 'ERROR') {
                setError(res.data.message);
              } else {
                if (codeRef.current) {
                  codeRef.current.value = '';
                }
                setError(null);
                setCodeSent(true);
              }
            }
          } else {
            if (res.status == 200 && res.data.type === 'SUCCESS') {
              setDone(true);
              setTimeout(() => {
                window.location.href = '/login';
              }, 3000);
            } else {
              setTokenError(res.data.message);
            }
          }
          setLoading(false);
        });
  }

  return (
    <Fragment>
      <div className="main-wrap">
        <div className="nav-header bg-transparent shadow-none border-0">
          <div className="nav-top w-100">
            <a href="/"><img src="/assets/images/REAL_LOGO.svg" alt="logo" style={{height: '50px'}}/><span
              className="ms-2 d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">onrain </span>
            </a>
            <button className="nav-menu me-0 ms-auto"></button>

            <a href="/login"
              className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
            <a href="/register"
              className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a>
          </div>
        </div>


        <div className="row">
          <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
            style={{backgroundImage: `url("/assets/images/login-side-image.png")`}}></div>

          <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div className="card-body rounded-0 text-left">
                <h2 className="fw-700 display1-size display2-md-size mb-4">Password reset</h2>
                {codeSent ? <form key="code-sent">
                  <div className="form-group icon-input mb-3">
                    <input type="text"
                      ref={codeRef}
                      name="resetcode"
                      className={`style2-input ps-5 form-control text-grey-900 font-xss ls-3 ${tokenError ? 'is-invalid' : ''}`}
                      placeholder="Reset Code"/>
                    <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                    <div className="invalid-feedback text-red font-xsss fw-500 ">
                      {tokenError}
                    </div>
                  </div>
                  <div className="form-group icon-input mb-3">
                    <input type="text"
                      name="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className={`style2-input ps-5 form-control text-grey-900 font-xss ls-3`}
                      placeholder="Account Username"/>
                    <i className="font-sm ti-user text-grey-500 pe-0"></i>
                    {/* <div className="invalid-feedback text-red font-xsss fw-500 ">*/}
                    {/*      Reset Code is invalid or does not belong to this user.*/}
                    {/* </div>*/}
                  </div>
                  <div className="form-group icon-input mb-1">
                    <input type="Password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className={`style2-input ps-5 form-control text-grey-900 font-xss ls-3 ${(passwordError|| errorPasswordNotMatch) && 'is-invalid'}`}
                      placeholder="Password"/>
                    <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                  </div>
                  <div className="mb-3 bg-grey p-2 rounded-3">
                    <div>
                      <input type="checkbox"
                        className={`form-check-input mt-2 me-2 ${passwordError && !(password.length > 6) && 'is-invalid'}`}
                        checked={password.length > 6} readOnly={true}/>
                      <label className="form-check-label font-xsss checkbox-text pt-1">6
                                    characters long</label>
                    </div>
                    <div>
                      <input type="checkbox"
                        className={`form-check-input mt-2 me-2 ${passwordError && !/[a-z]/.test(password) && 'is-invalid'}`}
                        checked={/[a-z]/.test(password)} readOnly={true}/>
                      <label className="form-check-label font-xsss checkbox-text pt-1">1
                                    lowercase letter</label>
                    </div>
                    <div>
                      <input type="checkbox"
                        className={`form-check-input mt-2 me-2 ${passwordError && !/[A-Z]/.test(password) && 'is-invalid'}`}
                        checked={/[A-Z]/.test(password)} readOnly={true}/>
                      <label className="form-check-label font-xsss checkbox-text pt-1">1
                                    uppercase letter</label>
                    </div>
                    <div>
                      <input type="checkbox"
                        className={`form-check-input mt-2 me-2 ${passwordError && username.toLowerCase() == password.toLowerCase() && 'is-invalid'}`}
                        checked={username.toLowerCase() != password.toLowerCase()}
                        readOnly={true}/>
                      <label className="form-check-label font-xsss checkbox-text pt-1">Different
                                    from username</label>
                    </div>
                  </div>
                  <div className="form-group icon-input mb-3">
                    <input type="Password"
                      name="repeatPassword"
                      value={repeatPassword}
                      onChange={(e)=>setRepeatPassword(e.target.value)}
                      className={`style2-input ps-5 form-control text-grey-900 font-xss ls-3 ${errorPasswordNotMatch && 'is-invalid'}`}
                      placeholder="Confirm Password"/>
                    <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                    <div className="invalid-feedback text-red font-xsss fw-500 ">
                                Passwords do not match
                    </div>
                  </div>


                  <div className="col-sm-12 p-0 text-left">
                    <div className="form-group mb-1"><a href="#" onClick={submit}
                      className={`${loading ? 'disabled' : ''}  form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 `}>{done ? 'Successfully Reset Password' : 'Reset Password'}</a></div>
                  </div>
                </form> :
                <form key="not-code-sent">
                  <div className="form-group icon-input mb-4">
                    <input type="text"
                      ref={phonenumberRef}
                      className={`style2-input ps-5 form-control text-grey-900 font-xss ls-3  ${error != null ? 'is-invalid' : ''}`}
                      placeholder="Your In Game Phone Number"/>
                    <i className="font-sm ti-mobile text-grey-500 pe-0"></i>
                    <div className="invalid-feedback text-red font-xsss fw-500 ">
                      {error}
                    </div>
                  </div>


                  <div className="col-sm-12 p-0 text-left">
                    <div className="form-group mb-1"><a onClick={submit}
                      className={` ${loading ? 'disabled' : ''} form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0`}>Request
                                            Password Reset</a></div>
                  </div>
                </form>
                }
              </div>
            </div>
          </div>

        </div>

      </div>
    </Fragment>
  );
}
