import {Fragment} from 'react';
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import Notfound from './Notfound';
import Loading from '../components/Loading';
import PostCard from '../components/PostCard';

import useSWR from 'swr';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import RightMessages from '../components/header/RightMessages';

export default function PostPage() {
  const {tag, post: postId} = useParams<any>();

  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };

  const {data} = useSWR(`${process.env.REACT_APP_API_URL}/profile/${tag}/post/${postId}`, fetcher);

  if (!data) return <Loading/>;
  if (data.status !== 200) return <Notfound/>;

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <RightMessages/>


      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <PostCard post={data.data} seeMore={false}/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
}
