import {Fragment, useRef, useState} from 'react';
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import {useUser} from '../helpers/auth-context';
import RequireUser from '../components/RequireUser';
import axios from 'axios';
import RightMessages from '../components/header/RightMessages';

export default function LinkAccountPage() {
  const {user} = useUser();
  const [loading, setLoading] = useState(false);
  const [sentCode, setSentCode] = useState(false);
  const [error, setError] =useState(null);
  const [success, setSuccess] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  if (!user) return <RequireUser> </RequireUser>;

  async function submit() {
    if (loading) return;
    setLoading(true);
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/verify`, sentCode ? {action: 'VERIFY', code: inputRef.current!.value} : {action: 'SUBMIT', phonenumber: inputRef.current!.value}, {withCredentials: true});
    const data =res.data;
    if (data.type === 'ERROR') {
      setError(data.message);
    } else if (data.type === 'SUCCESS' && !sentCode) {
      setSentCode(true);
      inputRef.current!.value = '';
      inputRef.current!.placeholder = 'Enter code here';
    } else if (data.type === 'SUCCESS') {
      setSuccess(true);
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
      return;
    }
    setLoading(false);
    if (data.type === 'MANUAL') {
      setError('Manual verification is needed. Please contact staff to do this.');
      setLoading(true);
    }
  }

  return (
    <Fragment>
      <Header ignoreUnverified={true} />
      <Leftnav />
      <RightMessages />


      <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">

                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4 className="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">Link Minecraft Account</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">{sentCode ? 'Verification Code' : '(In Game) Phone Number'}</label>
                        <input type="text"
                          placeholder={!sentCode ? '030123456' : ''} className={`form-control ${error != null && 'is-invalid'}`}
                          ref={inputRef}/>
                        <div className="invalid-feedback text-red font-xsss fw-500 position-absolute">
                          {error}
                        </div>
                        <div className="text-success font-xsss fw-500 position-absolute">
                          {sentCode && 'Successfully sent verification code!'}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 align-content-end d-flex">
                      <a role="button"
                        className={`${loading && 'disabled'} ms-auto bg-current text-center text-white font-xsss fw-600 p-3 w250 rounded-3 d-inline-block`}
                        onClick={submit}>{success ? 'Successfully Verified' : (sentCode ? 'Confirm Verification Code' : 'Send Verification Code')}</a>
                    </div>
                  </div>
                  {/* <div className="nav-caption fw-600 font-xssss text-grey-500 mb-2">Genaral</div>*/}
                  {/* <ul className="list-inline mb-4">*/}
                  {/*  <li className="list-inline-item d-block border-bottom me-0"><Link to="/accountinformation" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-primary-gradiant text-white feather-home font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Acount Information</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>*/}
                  {/*  <li className="list-inline-item d-block border-bottom me-0"><Link to="/contactinformation" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-gold-gradiant text-white feather-map-pin font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Saved Address</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>*/}
                  {/*  <li className="list-inline-item d-block me-0"><Link to="/socialaccount" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-red-gradiant text-white feather-twitter font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Social Acount</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>*/}
                  {/* </ul>*/}

                  {/* <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">Acount</div>*/}
                  {/* <ul className="list-inline mb-4">*/}
                  {/*  <li className="list-inline-item d-block border-bottom me-0"><Link to="/payment" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-mini-gradiant text-white feather-credit-card font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">My Cards</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>*/}
                  {/*  <li className="list-inline-item d-block  me-0"><Link to="/password" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-blue-gradiant text-white feather-inbox font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Password</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>*/}

                  {/* </ul>*/}

                  {/* <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">Other</div>*/}
                  {/* <ul className="list-inline">*/}
                  {/*  <li className="list-inline-item d-block border-bottom me-0"><Link to="/defaultnoti" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-gold-gradiant text-white feather-bell font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Notification</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>*/}
                  {/*  <li className="list-inline-item d-block border-bottom me-0"><a href="/helpbox" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-primary-gradiant text-white feather-help-circle font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Help</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></a></li>*/}
                  {/*  <li className="list-inline-item d-block me-0"><a href="/login" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-red-gradiant text-white feather-lock font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Logout</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></a></li>*/}

                  {/* </ul>*/}


                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
}
