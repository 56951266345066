import React, {Component, Fragment} from 'react';
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Createpost from '../components/Createpost';
import Load from '../components/Load';
import {Link, useParams} from 'react-router-dom';
import {useUser} from '../helpers/auth-context';
import {useCommunityModeratorsQuery, useCommunityQuery} from '../store/community/communityApi';
import Notfound from './Notfound';
import CommunityCard from '../components/CommunityCard';
import RightMessages from '../components/header/RightMessages';
import {Community, CommunityMember, CommunityRequest, CommunityRole} from '@prisma/client';
import CommunityPostList from '../components/CommunityPostList';
import {BsPatchCheckFill} from 'react-icons/bs';


export default function CommunityPage() {
  const {tag} = useParams<{tag: string}>();
  const {user} = useUser();
  const {data: community, isError} = useCommunityQuery(tag);
  const {data: moderators} = useCommunityModeratorsQuery(tag);

  if (isError) return <Notfound />;

  if (!community) return (<Load></Load>);
  const membership:CommunityMember & {community: Community} | undefined = user?.activeProfile?.profile?.communities?.find((m:CommunityMember) => m.communityId === community.id);
  const requested = membership === undefined && user?.activeProfile?.profile?.communityRequests?.find((m: CommunityRequest) => m.communityId === community.id) !== undefined;

  const sortMap = {
    'OWNER': 1,
    'ADMIN': 2,
    'MODERATOR': 3,
    'MEMBER': 4,
  };
  const sort = (a: CommunityMember, b: CommunityMember) => {
    return sortMap[a.role] - sortMap[b.role];
  };

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <RightMessages />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                <CommunityCard community={community} membership={membership} requested={requested}/>
                <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                  <div className="card-body d-block p-4">
                    <h4 className="fw-700 mb-3 font-xsss text-grey-900">Description</h4>
                    <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">{community.description}</p>
                  </div>
                </div>
                <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 pb-3">
                  <div className="card-body d-flex align-items-center p-4">
                    <h4 className="fw-700 mb-0 font-xssss text-grey-900">Moderators</h4>
                  </div>
                  {[...(moderators || [])].sort(sort).map(({profile, role}, index) => (
                    <div className="wrap" key={index}>
                      <Link to={`/p/${profile.tag}`}
                        className="card-body d-flex ps-2 pe-2 pb-0 bor-0 p-2 bg-greylight bg-themedark-light ms-3 me-3 rounded-3 mb-2">
                        <figure className="avatar me-3 m-0 mb-2"><img
                          src={`${process.env.REACT_APP_API_URL}/image/${profile.pictureUid}/thumb.webp`}
                          alt="avater"
                          className="shadow-sm rounded-circle w45"/></figure>
                        <h4 className="fw-700 text-grey-900 font-xssss mt-1 position-relative">{profile.name}
                          <span
                            className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">{role.charAt(0)}{role.toLowerCase().substring(1)}</span>
                        </h4>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-xl-8 col-xxl-9 col-lg-8 mt-3">
                {membership !== undefined && <Createpost community={community}/>}
                {(community.type !== 'PRIVATE' || membership !== undefined) ?
                    <CommunityPostList community={community}/> :
                    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 text-center">
                      <div className="card-body d-block p-4">
                        <h4 className="fw-700 mb-1 font-xsss text-grey-900">Private Community</h4>
                        <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">This community is private and only
                          members can view this community.</p> <br/>
                        <i className="feather-lock text-grey-500 m-3" style={{fontSize: '62px'}}></i>
                      </div>
                    </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
