import React, {Fragment} from 'react';
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Pagetitle from '../components/Pagetitle';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import {useUser} from '../helpers/auth-context';
import RequireUser from '../components/RequireUser';
import {GiPartyPopper} from 'react-icons/gi';
import {Link, useParams} from 'react-router-dom';
import FollowRequestButtons from '../components/FollowRequestButtons';
import {useAnswerRequestMutation, useCommunityQuery, useCommunityRequestsQuery} from '../store/community/communityApi';
import Notfound from './Notfound';
import Load from '../components/Load';
import {Community, CommunityMember, CommunityRequest} from '@prisma/client';
import NotAllowed from './NoPermission';
import Loading from '../components/Loading';
import Button from '../components/Button';
import RightMessages from '../components/header/RightMessages';

const API_URL = process.env.REACT_APP_API_URL;
export default function CommunityRequests() {
  const {tag} = useParams<{tag: string}>();
  const {user} = useUser();
  const {data: community, isError} = useCommunityQuery(tag);
  const {data: requests, refetch} = useCommunityRequestsQuery(tag);
  const [answerRequest, {isLoading}] = useAnswerRequestMutation();

  if (isError) return <Notfound />;

  if (!community || !requests) return (<Loading />);
  const membership:CommunityMember & {community: Community} | undefined = user?.activeProfile?.profile?.communities?.find((m:CommunityMember) => m.communityId === community.id);

  if (!membership || !['ADMIN', 'MODERATOR', 'OWNER'].includes(membership.role)) return <NotAllowed />;

  function answer(action: 'ACCEPT' | 'REJECT', id: string) {
    answerRequest({id, tag, action}).unwrap().then(() => refetch());
  }

  return (
    <Fragment>
      <Header/>
      <Leftnav/>
      <RightMessages/>

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <Pagetitle title={`Join Requests for ${community.name}`}/>

                <div className="row ps-2 pe-2">
                  {requests.length > 0 ? requests.map(({profile, ...request}, index) => (
                    <div key={index} className="col-md-4 col-sm-6 col-12 pe-2 ps-2">
                      <div
                        className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                        <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                          <Link to={`/p/${profile.tag}`}>
                            <figure
                              className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1">
                              <img
                                height={65}
                                width={65}
                                src={`${API_URL}/image/${profile.pictureUid}/thumb.webp`}
                                alt="avatar"
                                className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"/>
                            </figure>
                            <div className="clearfix w-100"></div>
                            <h4 className="fw-700 font-xsss mt-3 mb-0">{profile.name} </h4>
                            <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">@{profile.tag}</p>
                          </Link>
                          <div>
                            <Button
                              className="mt-0 btn py-2 px-3 lh-24 mx-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                              loading={isLoading}
                              onClick={() => answer('ACCEPT', request.id)}>
                                    Accept
                            </Button>
                            <Button
                              className="mt-0 btn py-2 px-3 lh-24 mx-1 ls-3 d-inline-block rounded-xl bg-danger font-xsssss fw-700 ls-lg text-white"
                              loading={isLoading}
                              onClick={() => answer('REJECT', request.id)}>
                                    Reject
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )) : <div className="col-md-12 col-sm-12 pe-2 ps-2">
                    <div
                      className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                      <div
                        className="card-body d-block w-100 ps-3 pe-3 pb-0 text-center text-grey-500">
                        <GiPartyPopper className="" size={52}/>
                        <div className="clearfix w-100"></div>
                        <p>You don&#39;t have any incoming requests!</p>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
}
