import useSWR from 'swr';
import axios from 'axios';
import {getRelativeTime} from '../../helpers/utils';
import {Link} from 'react-router-dom';
import {BsPatchCheckFill} from 'react-icons/bs';
import {IoMdBusiness} from 'react-icons/io';

export default function NewProfiles() {
  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };
  const {data, error} = useSWR(`${process.env.REACT_APP_API_URL}/feeds/latest-profiles`, fetcher);

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 pb-3">
      <div className="card-body d-flex align-items-center p-4">
        <h4 className="fw-700 mb-0 font-xssss text-grey-900">New to Onrain.</h4>
      </div>
      {data !== undefined ? data.data.map((profile, index) => (
        <div className="wrap" key={index}>
          <Link to={`/p/${profile.tag}`} className="card-body d-flex ps-2 pe-2 pb-0 bor-0 p-2 bg-greylight bg-themedark-light ms-3 me-3 rounded-3 mb-2">
            <figure className="avatar me-3 m-0 mb-2"><img src={`${process.env.REACT_APP_API_URL}/image/${profile.pictureUid}/thumb.webp`} alt="avater" className="shadow-sm rounded-circle w45" /></figure>
            <h4 className="fw-700 text-grey-900 font-xssss mt-1 position-relative">{profile.name}
              {profile.businessProfile?.verified === true && <span title="This is a verified store or business within Karakura and can be trusted" className="profile-badge ms-1 text-theme font-sm" style={{lineHeight: '0'}}><IoMdBusiness/></span>}
              {profile.businessProfile?.verified === false && <span title="This is an unverified business profile" className="profile-badge ms-1 font-sm" style={{lineHeight: '0'}}><IoMdBusiness/></span>}
              {profile.verified && <span title="The identity of this account is verified" className="profile-badge ms-1 text-theme font-sm" style={{lineHeight: '0'}}><BsPatchCheckFill/></span>}
              <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Joined {getRelativeTime(new Date(profile.createdAt))}</span></h4>
          </Link>
        </div>
      )) : <></>}
    </div>
  );
}

