import {ReactNode, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import * as React from 'react';

type AlertProps = {
    title: string;
    content?: string;
    dismissible?: boolean;
    link?: string;
    linkText?: string;
    smooth?: boolean;
    onClose?: (v: void) => void;
    onConfirm?: (v: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    childNode?: ReactNode;
    onDismiss: () => void;
}

export default function Alert({
  title, content = '', dismissible = true, onDismiss, link, linkText, smooth = false, onClose = () => {
  }, onConfirm = undefined, childNode = undefined,
}: AlertProps) {
  // const [dismissed, setDismissed] = useState(false);
  const [opened, setOpened] = useState(smooth);

  useEffect(() => {
    setTimeout(() => {
      setOpened(true);
    }, 200);
  });

  return (<div
    className={`min-vw-100 position-absolute pointer-events-none alert-wrapper position-fixed ${opened ? 'visible' : 'hidden'}`}
    style={{zIndex: 30}}>
    <div className="position-relative pointer-events-none" style={{zIndex: 50}}>
      <div className="bg-white p-4 right-0 rounded-xxl border-0 shadow-lg mx-auto app-alert"
        style={{width: 'fit-content'}}>
        <div className="row">
          <div className="col">
            <h4 className="fw-700 font-xss mb-2">{title}</h4>
          </div>
          <div className="col-auto">
            {dismissible && (<a href="/" className="app-alert-close" onClick={(e) => {
              e.preventDefault();
              // setDismissed(true);
              onDismiss();
              onClose();
            }}><i
                className="feather-x"></i></a>)}
          </div>
        </div>
        {childNode === undefined ? <>
          <p className="pb-2">{content}</p>
          {link !== undefined && <Link
            className="p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl"
            to={link}>{linkText}</Link>}
        </> : <>{childNode}</>
        }
        {onConfirm !== undefined && (
          <div className="row">
            <div className="col"></div>
            <div className="col-auto">
              <a
                className="p-2 ms-auto lh-20 w100 bg-dark me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl"
                href="#" onClick={(e) => {
                  e.preventDefault();
                  // setDismissed(true);
                  onDismiss();
                  onClose();
                }}>No</a>
              <a
                className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl"
                href="#" onClick={(e) => {
                  e.preventDefault();
                  // setDismissed(true);
                  onDismiss();
                  onConfirm(e);
                }}>Yes</a>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>);
}
