import React, {Component, Fragment} from 'react';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import {useUser} from '../helpers/auth-context';

const API_URL = process.env.REACT_APP_API_URL;

class RegisterPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: props.user !== null,
      username: '',
      password: '',
      repeatPassword: '',
      phonenumber: '',
      terms: false,
      error: {
        username_invalid: false,
        password: false,
        password_not_match: false,
        username_exists: false,
        phonenumber_used: false,
        phonenumber_invalid: false,
        terms: false,
      },
      loading: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    axios.get(`${API_URL}/auth/me`, {withCredentials: true})
        .then((res) => {
          if (res.data != false) {
            this.setState({
              loggedIn: true,
            });
          }
        });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.loading) return;

    this.setState({
      error: {
        username_invalid: !/\w{3,}/.test(this.state.username),
        password: !(this.state.password.length > 6) ||
                    !/[a-z]/.test(this.state.password) ||
                    !/[A-Z]/.test(this.state.password) ||
                    this.state.username.toLowerCase() == this.state.password.toLowerCase(),
        password_not_match: this.state.password !== this.state.repeatPassword,
        username_exists: false,
        // phonenumber_used: false,
        // phonenumber_invalid: !/^030\d{3,}$/.test(this.state.phonenumber),
        terms: !this.state.terms,
      },
    });

    if (Object.values(this.state.error).find((o) => o === true)) {
      return;
    }

    this.setState({loading: true});
    axios.post(`${API_URL}/auth/register`, {
      username: this.state.username,
      password: this.state.password,
      phonenumber: '',
    })
        .then((res) => {
          const data = res.data;
          if (data.type === 'ERROR') {
            if (data.errno === 12) {
              this.setState({error: {username_exists: true}, loading: false});
            } else if (data.errno === 13) {
              this.setState({error: {phonenumber_used: true}, loading: false});
            } else {
              this.setState({loading: false});
            }
          } else if (data.type === 'OK') {
            axios.post(`${API_URL}/auth/login`, {
              username: this.state.username,
              password: this.state.password,
            }, {withCredentials: true})
                .then((res) => {
                  if (res.data === 'OK') {
                    document.location.href = '/';
                  }
                });
          }
        })
        .catch((err) => {
          console.log(err);
        }).finally(() => this.setState({loading: false}));
  }


  render() {
    return (
      <Fragment>
        <div className="main-wrap">
          <div className="nav-header bg-transparent shadow-none border-0">
            <div className="nav-top w-100">
              <a href="/"><img src="/assets/images/REAL_LOGO.svg" alt="logo" style={{height: '50px'}}/><span className="ms-2 d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">onrain </span> </a>
              <button className="nav-menu me-0 ms-auto"></button>

              <a href="/login"
                className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
              <a href="/register"
                className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a>
            </div>
          </div>


          <div className="row">
            <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
              style={{backgroundImage: `url("/assets/images/login-side-image.png")`}}></div>
            <div
              className="col-xl-7 align-items-center d-flex bg-white rounded-3 centered-card">
              <div className="card shadow-none border-0 ms-auto me-auto login-card">
                <div className="card-body rounded-0 text-left">
                  <h2 className="fw-700 display1-size display2-md-size mb-4">Create <br/>your
                                            account</h2>
                  <form>

                    <div className="form-group icon-input mb-3">
                      <i className="font-sm ti-user text-grey-500 pe-0"></i>
                      <input type="text"
                        name="username"
                        value={this.state.username}
                        onChange={this.handleInputChange}
                        className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600 ${(this.state.error.username_exists || this.state.error.username_invalid) && 'is-invalid'}`}
                        placeholder="Your Username"/>
                      <div className="invalid-feedback text-red font-xsss fw-500 ">
                        {this.state.error.username_invalid && 'Username can only contain letters and must be at least 3 characters long.'}
                        {this.state.error.username_exists && 'This username already exists.'}
                      </div>
                    </div>
                    {/* <div className="form-group icon-input mb-3">*/}
                    {/*  <i className="font-sm ti-mobile text-grey-500 pe-0"></i>*/}
                    {/*  <input type="text"*/}
                    {/*    name="phonenumber"*/}
                    {/*    value={this.state.phonenumber}*/}
                    {/*    onChange={this.handleInputChange}*/}
                    {/*    className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600 ${(this.state.error.phonenumber_invalid || this.state.error.phonenumber_used) && 'is-invalid'}`}*/}
                    {/*    placeholder="Your In Game Phone Number "/>*/}
                    {/*  <div className="invalid-feedback text-red font-xsss fw-500 ">*/}
                    {/*    {this.state.error.phonenumber_invalid && 'Invalid phone-number, make sure it\'s written like 030000000'}*/}
                    {/*    {this.state.error.phonenumber_used && 'This phone-number is already in use.'}*/}
                    {/*  </div>*/}
                    {/* </div>*/}
                    <div className="form-group icon-input mb-1">
                      <input type="Password"
                        name="password"
                        value={this.state.password}
                        onChange={this.handleInputChange}
                        className={`style2-input ps-5 form-control text-grey-900 font-xss ls-3 ${(this.state.error.password || this.state.error.password_not_match) && 'is-invalid'}`}
                        placeholder="Password"/>
                      <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                    </div>
                    <div className="mb-3 bg-grey p-2 rounded-3">
                      <div>
                        <input type="checkbox"
                          className={`form-check-input mt-2 me-2 ${this.state.error.password && !(this.state.password.length > 6) && 'is-invalid'}`}
                          checked={this.state.password.length > 6} readOnly={true}/>
                        <label className="form-check-label font-xsss checkbox-text pt-1">6
                                                        characters long</label>
                      </div>
                      <div>
                        <input type="checkbox"
                          className={`form-check-input mt-2 me-2 ${this.state.error.password && !/[a-z]/.test(this.state.password) && 'is-invalid'}`}
                          checked={/[a-z]/.test(this.state.password)} readOnly={true}/>
                        <label className="form-check-label font-xsss checkbox-text pt-1">1
                                                        lowercase letter</label>
                      </div>
                      <div>
                        <input type="checkbox"
                          className={`form-check-input mt-2 me-2 ${this.state.error.password && !/[A-Z]/.test(this.state.password) && 'is-invalid'}`}
                          checked={/[A-Z]/.test(this.state.password)} readOnly={true}/>
                        <label className="form-check-label font-xsss checkbox-text pt-1">1
                                                        uppercase letter</label>
                      </div>
                      <div>
                        <input type="checkbox"
                          className={`form-check-input mt-2 me-2 ${this.state.error.password && this.state.username.toLowerCase() === this.state.password.toLowerCase() && 'is-invalid'}`}
                          checked={this.state.username.toLowerCase() !== this.state.password.toLowerCase()}
                          readOnly={true}/>
                        <label className="form-check-label font-xsss checkbox-text pt-1">Different
                                                        from username</label>
                      </div>
                    </div>
                    <div className="form-group icon-input mb-1">
                      <input type="Password"
                        name="repeatPassword"
                        value={this.state.repeatPassword}
                        onChange={this.handleInputChange}
                        className={`style2-input ps-5 form-control text-grey-900 font-xss ls-3 ${this.state.error.password_not_match && 'is-invalid'}`}
                        placeholder="Confirm Password"/>
                      <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                      <div className="invalid-feedback text-red font-xsss fw-500 ">
                                                    Passwords do not match
                      </div>
                    </div>
                    <div className="form-check text-left mb-3">
                      <input type="checkbox"
                        className={`form-check-input mt-2 ${this.state.error.terms && 'is-invalid'}`}
                        name="terms" checked={this.state.terms}
                        onChange={this.handleInputChange}/>
                      <label className="form-check-label font-xsss checkbox-text pt-1">Accept
                                                    Term
                                                    and Conditions</label>
                      <div className="invalid-feedback text-red font-xsss fw-500 ">
                                                    You must accept the terms and conditions to create an account.
                      </div>

                    </div>
                  </form>

                  <div className="col-sm-12 p-0 text-left">
                    <div className="form-group mb-1"><a href='#' onClick={this.handleSubmit}
                      className={`form-control text-center style2-input text-white fw-600 ${this.state.loading ? 'bg-darkish' : 'bg-dark'} border-0 p-0 `}>Register</a>
                    </div>
                    <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">Already have
                                                account <a href="/login" className="fw-700 ms-1">Login</a></h6>
                  </div>

                  <div className="col-sm-12 p-0 text-center mt-2">

                    <h6 className="mb-0 d-inline-block bg-white fw-500 font-xsss text-grey-500 mb-3">Or, Sign up an other way </h6>
                    {/* <div className="form-group mb-1">*/}
                    {/*  <a href="/login" className="form-control text-left style2-input text-white fw-600 bg-current border-0 p-0 mb-2">*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"*/}
                    {/*      fill="currentColor" className="bi bi-link ms-2 w40 mb-1 me-5" viewBox="0 0 16 16">*/}
                    {/*      <path*/}
                    {/*        d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>*/}
                    {/*      <path*/}
                    {/*        d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"/>*/}
                    {/*    </svg> Get link via Text</a>*/}
                    {/* </div>*/}
                    <div className="form-group mb-1">
                      <a href={`${process.env.REACT_APP_API_URL}/auth/discord`} className="form-control text-left style2-input text-white fw-600 border-0 p-0 mb-2" style={{backgroundColor: '#5865F2'}}>
                        <img className="ms-3 me-5" src={'/assets/images/discord-mark-white.svg'} width={32} height={32} /> Sign up with Discord</a>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </Fragment>
    );
  }
}

export default function Register() {
  const {user} = useUser();
  return <RegisterPage user={user} />;
};
