import {Event, Profile} from '@prisma/client';
import {fetcher} from '../helpers/utils';
import useSWR from 'swr';
import {Icon} from '@iconify/react';
import React from 'react';

export default function UpcomingEvents() {
  const {data: events} = useSWR<(Event & {profile: Profile})[]>(`${process.env.REACT_APP_API_URL}/events/upcoming`, fetcher);

  if ((events || []).length === 0) {
    return <></>;
  }

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-flex align-items-center p-4">
        <h4 className="fw-700 mb-0 font-xssss text-grey-900">Events</h4>
        <a href="/events" className="fw-600 ms-auto font-xssss text-primary">See all</a>
      </div>
      {(events || []).map((value, index) => {
        const month = new Date(value.startDate).toLocaleString('default', {month: 'short'});
        const day = new Date(value.startDate).getDate();


        return (
          <div key={index} className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden">
            <div className={`bg-success me-2 p-3 rounded-xxl bg-primary-gradiant h-100`}><h4 className="fw-700 font-lg ls-3 lh-1 text-white mb-0"><span className="ls-1 d-block font-xsss text-white fw-600">{month}</span>{day}</h4></div>
            <h4 className="fw-700 text-grey-900 font-xssss mt-2">{value.name} <span
              className="d-flex font-xssss fw-500 mt-0 lh-3 text-grey-500 align-items-center"> <i
                className="ti-location-pin me-1"></i>{value.location} </span>
            <span className="d-flex font-xssss fw-500 mt-0 lh-3 text-grey-500 align-items-center"> <Icon
              icon="ph:at-bold" className="me-1"/>{value.profile.tag} </span></h4>
          </div>

        );
      })}

    </div>
  );
}
