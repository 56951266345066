import React, {Component, Fragment, useState} from 'react';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import axios from 'axios';
import useSWR from 'swr';
import Loading from '../components/Loading';
import {useGetAdvertiserAccountQuery, useOpenAccountMutation} from '../store/ads/adsApi';
import PostCard from '../components/PostCard';
import Button from '../components/Button';
import Chart from 'react-apexcharts';
import {Icon} from '@iconify/react';
import Campaigns from '../components/ads/Campaigns';
import {useUser} from '../helpers/auth-context';
import NotAllowed from './NoPermission';
import RightMessages from '../components/header/RightMessages';
import {downloadURI} from '../helpers/utils';
import {FeatureType} from '@prisma/client';
import Select from 'react-select';

const teams = {
  ants: {
    name: 'Ants',
    description: 'The Onrain Team that is everwhere! Just like ants, we are all over Karakura and winning all the rewards!',
    icon: 'heroicons:bug-ant-16-solid',
    color: '#4e22a1',
    merch: {
      showcase: '/assets/images/hypeteam/merch/ants/showcase.png',
      male_tshirt: '/assets/images/hypeteam/merch/ants/male_tshirt.png',
      female_tshirt: '/assets/images/hypeteam/merch/ants/female_tshirt.png',
      beanie: '/assets/images/hypeteam/merch/ants/beanie.png',
    },
    leadProfile: 'OnrainAnts',
    featureType: {label: 'HypeTeam: Ants', value: FeatureType.HYPETEAM_ANTS},
  },
  aces: {
    name: 'Aces',
    description: 'The Onrain Team that always wins! There is no better card than aces. In this team we do not lose, we only win!',
    icon: 'gravity-ui:card-heart',
    color: '#E2546C',
    merch: {
      showcase: '/assets/images/hypeteam/merch/aces/showcase.png',
      male_tshirt: '/assets/images/hypeteam/merch/aces/male_tshirt.png',
      female_tshirt: '/assets/images/hypeteam/merch/aces/female_tshirt.png',
      beanie: '/assets/images/hypeteam/merch/aces/beanie.png',
    },
    leadProfile: 'OnrainAces',
    featureType: {label: 'HypeTeam: Aces', value: FeatureType.HYPETEAM_ACES},
  },
};

export default function HypeTeams() {
  const {user} = useUser();
  const [loading, setLoading] = useState<'ants' | 'aces' | null>(null);

  const [featureTag, setFeatureTag] = useState<string>('');
  const [featureType, setFeatureType] = useState<FeatureType | null>(null);
  const [featureLoading, setFeatureLoading] = useState<boolean>(false);

  const activeProfile = user.activeProfile?.profile;
  if (!activeProfile) return <NotAllowed/>;

  function joinTeam(teamId: 'ants' | 'aces') {
    setLoading(teamId);
    axios.post(`${process.env.REACT_APP_API_URL}/events/hypeteam/join`, {teamId}, {withCredentials: true}).then(() => {
      window.location.reload();
    }).finally(() => {
      setLoading(null);
    });
  }

  function feature(tag: string, type: FeatureType) {
    setFeatureLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/events/feature-tag`, {tag, type}, {withCredentials: true}).then(() => {
      // window.location.reload();
    }).finally(() => {
      setFeatureLoading(false);
    });
  }

  if (activeProfile.hypeTeam === null) {
    return (
      <Fragment>
        <Header />
        <Leftnav />
        <RightMessages />

        <div className="main-content bg-white right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                    <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">Join Onrain HypeTeams!</h2>
                    <p className="font-xssss fw-500 text-grey-500 lh-26">Join one of the two Onrain HypeTeams
                                and hype onrain during events whilst gaining awesome rewards and merch!</p>
                    {/* <Button onClick={() => openAccount()} loading={creating} className="btn w200 border-0 bg-primary-gradiant p-3 text-white fw-600 rounded-3 d-inline-block font-xssss">Open Account</Button>*/}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                    <div className="d-flex align-items-center w-100">
                      <Icon className="ms-auto me-auto mb-3" icon="heroicons:bug-ant-16-solid" color="#4e22a1"
                        fontSize={64}/>
                    </div>
                    <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">Join Onrain Ants!</h2>
                    <p className="font-xssss fw-500 text-grey-500 lh-26">Join the Onrain Team that is everwhere!
                            Just like ants, we are all over Karakura and winning all the rewards!</p>
                    <p className="font-xssss fw-500 text-grey-500 lh-26">When joining this team you will get access to our merch, and have above badge added to your profile!</p>

                    <Button
                      className="btn w200 border-0 p-3 text-white fw-600 rounded-3 d-inline-block font-xssss w-100"
                      style={{backgroundColor: '#4e22a1'}} loading={loading === 'ants'} onClick={() => joinTeam('ants')}>Join Onrain Ants</Button>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                    <div className="d-flex align-items-center w-100">
                      <Icon className="ms-auto me-auto mb-3" icon="gravity-ui:card-heart" color="#E2546C"
                        fontSize={64}/>
                    </div>
                    <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">Join Onrain Aces!</h2>
                    <p className="font-xssss fw-500 text-grey-500 lh-26">Join the Onrain Team that always wins!
                          There is no better card than aces. In this team we do not lose, we only win!</p>
                    <p className="font-xssss fw-500 text-grey-500 lh-26">When joining this team you will get access to
                          our merch, and have above badge added to your profile!</p>
                    <Button
                      className="btn w200 border-0 p-3 text-white fw-600 rounded-3 d-inline-block font-xssss w-100"
                      style={{backgroundColor: '#E2546C'}} loading={loading === 'aces'} onClick={() => joinTeam('aces')}>Join Onrain Aces</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Popupchat/>
        <Appfooter/>
      </Fragment>
    );
  } else {
    const team = activeProfile.hypeTeam === 'ANTS' ? teams.ants : teams.aces;
    const isLead = activeProfile.tag === team.leadProfile;
    const isStaff = user.staff;
    const possibleTypes = [];

    if (isLead) {
      possibleTypes.push(team.featureType);
    }

    if (isStaff) {
      possibleTypes.push({label: 'Official', value: FeatureType.OFFICIAL});
    }

    return (
      <Fragment>
        <Header/>
        <Leftnav/>
        <RightMessages/>

        <div className="main-content bg-white right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                    <div className="row">
                      <div className="col-lg-6 ps-lg-5">
                        <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">HypeTeam:<br/> Onrain {team.name}
                        </h2>
                        <p className="font-xssss fw-500 text-grey-500 lh-26">{team.description}</p>
                      </div>
                      <div className="col-lg-6 d-flex">
                        <Icon className="m-auto" icon={team.icon} color={team.color} fontSize={128}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                    <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">Onrain {team.name} Merch</h2>
                    <img src={team.merch.showcase} className="rounded-1"/>
                    <div className="d-flex flex-row pt-3">
                      <Button onClick={() => downloadURI(team.merch.male_tshirt, 'onrain-hypeteam-male-tshirt.png')} style={{backgroundColor: team.color}} className="btn w-100 me-2 border-0 p-3 text-white fw-600 rounded-3 d-inline-block font-xssss">Male T-Shirt</Button>
                      <Button onClick={() => downloadURI(team.merch.female_tshirt, 'onrain-hypeteam-female-tshirt.png')} style={{backgroundColor: team.color}} className="btn w-100 mx-2 border-0 p-3 text-white fw-600 rounded-3 d-inline-block font-xssss">Female T-Shirt</Button>
                      <Button onClick={() => downloadURI(team.merch.beanie, 'onrain-hypeteam-beanie.png')} style={{backgroundColor: team.color}} className="btn w-100 ms-2 border-0 p-3 text-white fw-600 rounded-3 d-inline-block font-xssss">Beanie</Button>
                    </div>
                    {/* <Button onClick={() => openAccount()} loading={creating} className="btn w200 border-0 bg-primary-gradiant p-3 text-white fw-600 rounded-3 d-inline-block font-xssss">Open Account</Button>*/}
                  </div>
                </div>
                {(isLead || isStaff) && <div className="col-sm-12">
                  <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                    <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">Onrain {team.name} Lead Panel</h2>
                    <h4 className="font-xl fw-700">Featured Tags</h4>
                    <div className="row align-items-end">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">#Tag</label>
                          <input type="text" className="form-control" onChange={(e) => setFeatureTag(e.target.value)}/>
                        </div>
                      </div>
                      <div className="col-lg-3 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Type</label>
                          <Select
                            options={possibleTypes}
                            onChange={(newValue) => {
                              setFeatureType(newValue.value as FeatureType);
                            }}
                            className="react-select react-fix"
                            classNamePrefix="react-select"
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 mb-3 h-100">
                        <Button onClick={() => feature(featureTag, featureType)} loading={featureLoading}
                          className="btn w200 border-0 bg-primary-gradiant p-3 text-white fw-600 rounded-3 d-inline-block font-xssss mt-auto">Feature Tag</Button>
                      </div>
                    </div>
                    {/* <Button onClick={() => openAccount()} loading={creating} className="btn w200 border-0 bg-primary-gradiant p-3 text-white fw-600 rounded-3 d-inline-block font-xssss">Open Account</Button>*/}
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>

        <Popupchat/>
        <Appfooter/>
      </Fragment>
    );
  }
}

