import {FaEllipsisH} from 'react-icons/fa';
import {useUser} from '../../helpers/auth-context';
import {useState} from 'react';
import axios from 'axios';
import {FiTrash2} from 'react-icons/fi';


type MessageGroupInviteRowProps = {
  slug: string,
  expire?: Date,
  usages?: number,
  onDelete: (slug:string) => void
}

export default function MessageGroupInviteRow({slug, expire, usages, onDelete}: MessageGroupInviteRowProps) {
  return <li key={slug}>
    <div
      className={`profile-entry rounded-3 py-3`}>
      <td
        className="email-subject text-grey-900 text-dark fw-600 font-xssss" style={{width: '30%'}}>
        {slug}
      </td>
      <td
        className="email-subject text-grey-900 text-dark font-xssss" style={{width: '35%'}}>
        {expire ? `Expires on ${expire.toLocaleString()}` : 'Does not expire'}
      </td>
      <td
        className="email-subject text-grey-900 text-dark font-xssss" style={{width: '30%'}}>
        {usages ? `${usages} usages left` : `No usage limit`}
      </td>
      <td className="ms-auto position-relative">
        <div className="cursor-pointer" onClick={() => onDelete(slug)}>
          <FiTrash2 className="text-danger"/>
        </div>
      </td>
    </div>
  </li>;
}
