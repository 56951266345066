import {Fragment, useState} from 'react';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import {useUser} from '../helpers/auth-context';
import RequireUser from '../components/RequireUser';
import {FaCrown, FaPencilAlt, FaUserAlt, FaWrench} from 'react-icons/fa';
import {FiX} from 'react-icons/fi';
import axios from 'axios';
import RightMessages from '../components/header/RightMessages';
import useSWR from 'swr';
import NotAllowed from './NoPermission';
import Loading from '../components/Loading';
import Button from '../components/Button';
import Select from 'react-select';

const API_URL = process.env.REACT_APP_API_URL;


const roleTypes = [
  {value: 'ADMIN', label: 'Admin'},
  {value: 'AUTHOR', label: 'Author'},
];


export default function ProfileMembers() {
  const {user} = useUser();
  const [addOpen, setAddOpen] = useState(false);
  const [type, setType] = useState(roleTypes[1].value);
  const [phonenumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);

  const toggleAdd = () => setAddOpen(!addOpen);

  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };

  const {data, mutate} = useSWR(`${process.env.REACT_APP_API_URL}/activeprofile/users`, fetcher);

  if (!user) {
    return <RequireUser> </RequireUser>;
  }

  if (!data) {
    return <Loading/>;
  }

  if (data.status === 403) {
    return <NotAllowed/>;
  }

  const users = data.data;

  function getRoleIcon(role) {
    switch (role.toLowerCase()) {
      case 'owner':
        return (<FaCrown/>);
      case 'admin':
        return (<FaWrench/>);
      case 'author':
        return (<FaPencilAlt/>);
      default:
        return (<FaUserAlt/>);
    }
  }

  function deleteUser(user) {
    axios.post(`${API_URL}/activeprofile/user/delete`, {
      user: user.phonenumber,
    }, {withCredentials: true})
        .then(() => {
          mutate();
        });
  }

  function addUser() {
    if (loading) return;

    axios.put(`${API_URL}/activeprofile/user`, {
      user: phonenumber,
      role: type,
    }, {withCredentials: true, validateStatus: (e) => true})
        .then(() => {
          mutate();
          setLoading(false);
          setPhoneNumber('');
          setAddOpen(false);
        });
  }

  return (

    <Fragment>
      <Header/>
      <Leftnav/>
      <RightMessages />

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">

                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col">
                          <h4 className="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">Profile Members</h4>
                        </div>
                        <div className="col-auto position-relative">
                          <a className="fw-700 ms-1 cursor-pointer" onClick={toggleAdd}>Add new member</a>
                          <div className={`position-absolute top-50 dropdown-menu p-3 right-0 rounded-xxl border-0 shadow-lg ${addOpen ? 'show' : ''}`} style={{width: 300}}>
                            <div className="form-group">
                              <label className="ms-auto mont-font fw-600 font-xsss mb-2">
                                Phone number
                              </label>
                              <input type="text"
                                className={`form-control`}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                              />
                              <label className="mont-font fw-600 font-xsss mb-2 mt-3">Role</label> <br/>
                              <Select
                                options={roleTypes}
                                defaultValue={roleTypes[1]}
                                onChange={(newValue) => {
                                  setType(newValue.value);
                                }}
                                className="react-select react-fix"
                                classNamePrefix="react-select"
                              />
                            </div>
                            <div className="mt-2">
                              <Button className="w-100 d-block" disabled={phonenumber.length < 6} loading={loading} onClick={addUser}>Add Member</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="profile-wrapper p-3 w-100 position-relative scroll-bar">
                        <ul className="profile-list">
                          {users.map((profileUser, index) => (

                            <li key={index}>

                              <div
                                className={`profile-entry rounded-3`}>
                                <div className="profile-user">
                                  <div style={{lineHeight: '12px'}}>
                                    <h6 className="font-xssss text-grey-900 mb-0 mt-0 fw-700">{profileUser.user.phonenumber}</h6>
                                    <span
                                      className="font-xssss text-grey-900"></span>
                                  </div>
                                </div>
                                <div
                                  className="email-subject text-grey-900 text-dark fw-600 font-xssss">
                                  <span
                                    className="font-sm me-2">{getRoleIcon(profileUser.role)}</span>
                                  {`${profileUser.role[0].toUpperCase()}${profileUser.role.slice(1).toLowerCase()}`}
                                </div>
                                <div className="ms-auto">
                                  {profileUser.role !== 'OWNER' && <a title="Remove access" href="/profile/list" onClick={(e) => {
                                    e.preventDefault();
                                    deleteUser(profileUser.user);
                                  }}
                                  className="ms-auto font-sm text-danger ms-auto mt-3"><FiX/></a>}
                                </div>
                              </div>
                            </li>

                          ))}


                        </ul>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <Popupchat/>
      <Appfooter/>
    </Fragment>);
}
