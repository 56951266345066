import {useUser} from '../helpers/auth-context';
import {useState} from 'react';
import {MdSend} from 'react-icons/md';
import axios from 'axios';
import {MoonLoader} from 'react-spinners';
import {useHistory} from 'react-router-dom';

type PostCardNewCommentProps = {
    postId: number,
    onPost?: (post: any) => void
}

export default function PostCardNewComment({
  postId, onPost = () => {
  },
}: PostCardNewCommentProps) {
  const {user} = useUser();
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  function postComment() {
    if (loading) return;
    if (message.length < 1 || message.length > 1000) {
      return;
    }
    setLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/post/comment`, {
      post: postId,
      message: message,
    }, {withCredentials: true})
        .then((res) => {
          onPost(res.data);
          setMessage('');
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
  }


  if (user?.activeProfile?.profile !== undefined) {
    return <div className="card-body p-0 mt-3 position-relative">
      <figure className="avatar position-absolute ms-2 mt-1 top-0">
        <img src={`${process.env.REACT_APP_API_URL}/image/${user.activeProfile.profile.pictureUid}/thumb.webp`}
          alt="icon"
          className="shadow-sm rounded-circle w30"/>
      </figure>
      <div className="position-absolute top-0 right-15 post-comment-send text-dark" onClick={postComment}>
        {loading ? <MoonLoader size={24} color={'#fff'} className="mt-1 dark-loader"/> : <MdSend/>}
      </div>
      <input type="text"
        className="bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
        placeholder="Comment on this post!"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        disabled={loading}
      />
    </div>;
  } else {
    return <div className="card-body p-0 mt-3 position-relative" onClick={() => window.location.href = '/register'}>
      <figure className="avatar position-absolute ms-2 mt-1 top-0">
        <img src={`/assets/images/default_user.svg`}
          alt="icon"
          className="shadow-sm rounded-circle w30"/>
      </figure>
      <div className="position-absolute top-0 right-15 post-comment-send text-dark">
        <MdSend/>
      </div>
      <input type="text"
        className="bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
        value={'Log in or create an account to post a comment!'}
        disabled
      />
    </div>;
  }
}
