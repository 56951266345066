import React, {Component, Fragment, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import {useUser} from '../helpers/auth-context';
import Select from 'react-select';
import axios from 'axios';
import RequireUser from '../components/RequireUser';
import RightMessages from '../components/header/RightMessages';

const API_URL = process.env.REACT_APP_API_URL;

export default function CreateProfile() {
  const {user, reloadUser} = useUser();
  const profileTypes = [
    {value: 'PERSONAL', label: 'Personal'},
    {value: 'ORGANISATION', label: 'Organization'},
  ];
  const profileVisibilities = [
    {value: 'PUBLIC', label: 'Public'},
    {value: 'PRIVATE', label: 'Private (followers only)'},
  ];

  const [type, setType] = useState(profileTypes[0].value);
  const [visibility, setVisibility] = useState(profileVisibilities[0].value);
  const [tag, setTag] = useState('');
  const [realname, setRealname] = useState('');
  const [loading, setLoading] = useState(false);

  const [tagError, setTagError] = useState(undefined);
  const [realnameError, setRealnameError] = useState(undefined);
  const [complete, setComplete] = useState(false);
  const history = useHistory();

  function submit(clickEvent) {
    clickEvent.stopPropagation();
    setTagError(undefined);
    setRealnameError(undefined);
    let r = false;
    if (!/^[a-z0-9]{3,16}$/i.test(tag)) {
      setTagError('Username tag must be alphanumeric and between 3 and 16 characters!');
      r = true;
    }
    if (!/^.{3,32}/.test(realname)) {
      setRealnameError('Realname must be between 3 and 32 characters!');
      r = true;
    }
    if (r) return;
    setLoading(true);
    axios.post(`${API_URL}/profile/create`, {
      type: type,
      visibility: visibility,
      tag: tag,
      name: realname,
    }, {withCredentials: true})
        .then((res) => {
          if (!res.data.success) {
            switch (res.data.errno) {
              case 12: {
                setTagError('Username already exists! Please choose a different one.');
                break;
              }
              default: {
                setTagError('An error occurred: ' + res.data.message);
                break;
              }
            }
            setLoading(false);
          } else {
            setComplete(true);
            reloadUser();
            setTimeout(() => {
              history.push('/profile/edit');
            }, 1500);
          }
        })
        .catch((err) => {
          setTagError('An error occurred: ' + err.message);
          setLoading(false);
        });

    // TODO: else make the request! and... update the user :(
  }

  return (
    <RequireUser>
      <Header/>
      <Leftnav/>
      <RightMessages />

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active  min-vh-100">

        <div className="middle-sidebar-bottom h-100">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                  <Link onClick={() => history.goBack()} className="d-inline-block mt-2"><i
                    className="ti-arrow-left font-sm text-white"></i></Link>
                  <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Create New Profile</h4>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <form action="#" onSubmit={submit}>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Profile
                                                        Tag</label>
                          <input type="text"
                            className={`form-control ${tagError && 'is-invalid'}`}
                            value={tag} onChange={(e) => setTag(e.target.value)}/>
                          {tagError &&
                                                        <div className="invalid-feedback text-red font-xsss fw-500 ">
                                                          {tagError}
                                                        </div>}
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Real Name</label>
                          <input type="text"
                            className={`form-control ${realnameError && 'is-invalid'}`}
                            value={realname}
                            onChange={(e) => setRealname(e.target.value)}/>
                          {realnameError &&
                                                        <div className="invalid-feedback text-red font-xsss fw-500 ">
                                                          {realnameError}
                                                        </div>}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Profile
                                                        Type</label> <br/>
                          <Select
                            options={profileTypes}
                            defaultValue={profileTypes[0]}
                            onChange={(newValue) => {
                              setType(newValue.value);
                            }}
                            className="react-select react-fix"
                            classNamePrefix="react-select"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label
                            className="mont-font fw-600 font-xsss mb-2">Visibility</label>
                          <br/>
                          <Select
                            options={profileVisibilities}
                            defaultValue={profileVisibilities[0]}
                            onChange={(newValue) => {
                              setVisibility(newValue.value);
                            }}
                            className="react-select"
                            classNamePrefix="react-select"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <a role="button"
                          className={`${loading && 'disabled'} bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block`}
                          onClick={submit}>{complete ? 'Profile Created!' : 'Create New Profile'}</a>
                      </div>
                    </div>

                  </form>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <Popupchat/>
      <Appfooter/>
    </RequireUser>
  );
}
