import {Fragment, useState} from 'react';
import Header from '../components/Header';
import {Link, useHistory, useParams} from 'react-router-dom';
import Button from '../components/Button';
import RequireUser from '../components/RequireUser';
import axios from 'axios';
import useSWR from 'swr';
import NotFound from '../pages/Notfound';

export default function MessageInvite() {
  const [loading, setLoading]=useState(false);
  const {slug} = useParams<{ slug: string }>();
  const history = useHistory();

  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };
  const {data, error} = useSWR(`${process.env.REACT_APP_API_URL}/messages/invite/${slug}/info`, fetcher);

  if (!data) return <></>;

  if (data.status != 200) return <NotFound/>;
  const group = data.data;

  function join() {
    if (loading) return;
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/messages/invite/${slug}/accept`, {withCredentials: true})
        .then((res) => {
          history.push('/messages/'+res.data.group);
          setLoading(false);
        }).catch((res) => {
          setLoading(false);
        });
  }

  return (<RequireUser>
    <Header/>
    <div className="main-content right-chat-active position-relative">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">
          <div className="row justify-content-center">
            <div className="col-xl-12 h-no-header align-items-center d-flex">

              <div className="card border-0 d-block p-3 mx-auto" style={{width: 'fit-content', maxWidth: 650}}>
                <h1 className="fw-700 text-grey-900 display2-size">You've been invited to {group.name}</h1>
                <p className="text-grey-500 font-xsss">Would you like to join this group?</p>
                <div className="d-flex flex-row align-content-end">
                  <Button className="btn-success p-3 ms-auto" color="green" onClick={join} loading={loading}>Join Group</Button>
                </div>
                {/* <Link to={link}*/}
                {/*  className="p-3 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-3 text-uppercase ls-3">{linkText}</Link>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </RequireUser>);
}
