import axios from 'axios';
import useSWR from 'swr';
import {MessageGroupPreview, ProfileInfo} from '../../api-types';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {getInitials} from '../../helpers/utils';
import {useUser} from '../../helpers/auth-context';
import {Message} from '@prisma/client';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function RightMessages() {
  const {user} = useUser();
  const [entries, setEntries] = useState<MessageGroupPreview[]>([]);

  const [width, height] = useWindowSize();
  const history = useHistory();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/messages/latest/groups?count=10`, {withCredentials: true, validateStatus: () => true})
        .then((res) => {
          if (res.status == 200) {
            setEntries(res.data);
          }
        });
  }, []);

  const [connect, setConnect] = useState<number>(0);
  const ws = useRef<WebSocket>(null);
  useEffect(() => {
    console.log('Attempting to connect to websocket server');
    ws.current = new WebSocket(process.env.REACT_APP_WS_URL);
    ws.current.onopen = () => console.log('Connection to websocket server established');
    ws.current.onclose = () => {
      console.log('Connection to websocket server lost...');
      setTimeout(() => {
        setConnect(connect + 1);
      }, 5000);
    };

    ws.current.onmessage = (e) => {
      const msg = JSON.parse(e.data);
      switch (msg.type) {
        case 'NEW_MESSAGE': {
          const message: Message & ProfileInfo = msg.data.message;
          const muted: boolean = msg.data.muted;
          setEntries((state) => {
            if (state.some((g) => g.id === message.groupId)) {
              const current = state.find((g) => g.id === message.groupId);
              state.splice(state.indexOf(current), 1);

              const currentGroup = window.location.pathname.split('messages/')[1] === message.groupId;
              return [{...current, lastMessage: message, unread: currentGroup || muted ? 0 : current.unread+1}, ...state];
            } else {
              axios.get(`${process.env.REACT_APP_API_URL}/messages/latest/groups?count=10`, {withCredentials: true})
                  .then((res) => {
                    const data: MessageGroupPreview[] = res.data;
                    const q = data.find((g) => g.id === message.groupId);
                    if (q !== undefined) {
                      setEntries((state) => [q, ...state].slice(0, 10));
                    }
                  });
              return state;
            }
          });
        }
      }
    };

    const client = ws.current;
    return () => {
      client.close();
      ws.current = null;
    };
  }, [connect]);

  if (!user) return <></>;
  return (<div id="main-content-wrap" className={`right-chat nav-wrap mt-2 right-scroll-bar ${width > 1500 ? 'active-sidebar' : ' '}`}>
    <div className="middle-sidebar-right-content bg-white shadow-xss rounded-xxl">

      <div className="section full pe-3 ps-4 py-4 position-relative feed-body">
        <h4 className="font-xsssss text-grey-500 text-uppercase fw-700 ls-3">RECENT MESSAGES</h4>
        <ul className="list-group list-group-flush">
          {entries.length == 0 && <li key={1}
            className={`bg-transparent list-group-item no-icon pe-0 ps-0 pt-2 pb-2 border-0 d-flex align-items-center me-0 position-relative`}>
            <h3 className="fw-700 mb-0 mt-0 me-5 mw-100 cursor-pointer" onClick={() => history.push(`/messages/`)}>
              <span className="font-xssss text-grey-600  d-block me-5 text-dark model-popup-chat pointer group-name">No recent messages!</span>
              <span className="d-block font-xsssss fw-500 mt-0 lh-3 me-5 text-grey-600 group-name">Start a chat to show your latest messages here.</span>
            </h3>
          </li>}
          {entries.length > 0 && entries.map((group, index) =>
            <li key={index}
              className={`bg-transparent list-group-item no-icon pe-0 ps-0 pt-2 pb-2 border-0 d-flex align-items-center ${group.unread > 0 ? 'me-4' : 'me-0'} position-relative`}>
              {group.imageUid != null ? <figure className="avatar float-left mb-0 me-2 cursor-pointer" onClick={() => history.push(`/messages/${group.id}`)}>
                <img src={`${process.env.REACT_APP_API_URL}/image/${group.imageUid}/thumb.webp`} alt="avater" className="w35 rounded-circle"/>
              </figure> :
                  <span className="btn-round-sm bg-primary-gradiant me-2 w35 mw35 mw-100 text-white font-xssss fw-700">{getInitials(group.name)}</span> }
              <h3 className="fw-700 mb-0 mt-0 me-5 mw-100 cursor-pointer" onClick={() => history.push(`/messages/${group.id}`)}>
                <span className="font-xssss text-grey-600  d-block me-5 text-dark model-popup-chat pointer group-name">{group.name}</span>
                <span className="d-block font-xsssss fw-500 mt-0 lh-3 me-5 text-grey-600 group-name">{group.lastMessage.systemMessage ? group.lastMessage.message : `${group.lastMessage.profile.name}: ${group.lastMessage.message}`}</span>
              </h3>
              {group.unread > 0 && <span className="right-chat-circle-count bg-warning position-absolute">{group.unread}</span>}
            </li>,
          )}
        </ul>
      </div>
    </div>
  </div>
  );
}
