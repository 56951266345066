import {Link} from 'react-router-dom';
import {CSSProperties} from 'react';
import axios from 'axios';
import useSWR from 'swr';
import PostCard from '../PostCard';

const API_URL = process.env.REACT_APP_API_URL;

export type SearchResultsProps = {
    query: string;
    className?: string;
    style?: CSSProperties;
    select?: (post: any) => void;
}

export default function SearchPostResults({query, className, style, select = () => {}} : SearchResultsProps): JSX.Element {
  const fetcher = async (url) => {
    if (query === '') return {data: []};
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };

  const {data} = useSWR(`${process.env.REACT_APP_API_URL}/search/ownposts?query=${query}`, fetcher);

  return (
    <div className={`card w-100 shadow-xss rounded-xxl border-0 mb-3 pt-3 scroll-bar ${className} z-index-1`}
      style={{...style,
        maxHeight: 'calc(100vh - 200px)'}}
      onClick={(e) => e.stopPropagation()}>
      {query == '' &&
                <div className="card-body d-flex pt-0 ps-4 pe-4 pb-3 bor-0">
                    start typing to search
                </div>
      }
      {data?.data && data.data.length > 0 && data.data.map((post, index) => (
        <div className="wrap" key={index}>
          <div className="hover-gray cursor-pointer" onClick={(e) => {
            e.stopPropagation();
            select(post);
          }}>
            <div className="pointer-events-none">
              <PostCard post={post} />
            </div>
          </div>
        </div>
      ))}
      {data?.data && data.data.length == 0 && query != '' &&
                <div className="card-body d-flex pt-0 ps-4 pe-4 pb-3 bor-0">
                    no results
                </div>
      }
      {!data?.data &&
                <div className="card-body d-flex pt-0 ps-4 pe-4 pb-3 bor-0">
                    loading...
                </div>
      }
    </div>
  );
}

