import {Fragment, useState} from 'react';
import Header from './Header';

export default function Loading() {
  const [color, setColor] = useState(undefined);
  return (
    <Fragment>
      <Header/>
      <style>
        {color && `
            #rainbow-button {
              background-color: #${color} !important;
            }
      `}
      </style>
      <div className="main-content pt-0 bg-white ps-0 pe-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 text-center default-page vh-100 align-items-center d-flex">
              <div className="card bg-transparent-card border-0 text-center d-block p-0">
                <h1 className="fw-700 text-grey-900 display3-size display4-md-size">Page is
                                    loading...</h1>
                <p className="text-grey-500 font-xsss">We are fetching the page for you... I may be a
                                    robot but I am not a quantum computer...</p>
                <a href="/"
                  className="p-3 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-3 text-uppercase ls-3"
                  id="rainbow-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setColor(Math.floor(Math.random() * 16777215).toString(16));
                  }}>Click because you can!</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

