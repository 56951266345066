import React, {Component, Fragment, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import {useUser} from '../helpers/auth-context';
import Select from 'react-select';
import axios from 'axios';
import RequireUser from '../components/RequireUser';
import RightMessages from '../components/header/RightMessages';
import {useForm} from 'react-hook-form';
import {Community, CommunityType} from '@prisma/client';
import Button from '../components/Button';
import {useCreateCommunityMutation} from '../store/community/communityApi';

const API_URL = process.env.REACT_APP_API_URL;

export default function CreateCommunity() {
  const {user, reloadUser} = useUser();
  const [createCommunity, {isLoading: loading}] = useCreateCommunityMutation();
  const communityTypes = [
    {value: CommunityType.PUBLIC, label: 'Open'},
    {value: CommunityType.INVITE_ONLY, label: 'Visible (Request to Join)'},
    {value: CommunityType.PRIVATE, label: 'Private'},
  ];

  const {register, handleSubmit, setValue, getValues, setError, formState: {errors}} = useForm<Omit<Community, 'imageUid' | 'id' | 'bannerUid' | 'groupId'>>();

  const history = useHistory();

  function submit(community: Omit<Community, 'imageUid' | 'id' | 'bannerUid' | 'groupId'>) {
    createCommunity(community).unwrap()
        .then((res) => {
          window.location.href = '/c/'+res.community.tag;
        })
        .catch((error) => {
          setError('tag', {message: error.data.message});
        });
  }

  return (
    <RequireUser>
      <Header/>
      <Leftnav/>
      <RightMessages />

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active  min-vh-100">

        <div className="middle-sidebar-bottom h-100">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                  <Link to="/" onClick={() => history.goBack()} className="d-inline-block mt-2"><i
                    className="ti-arrow-left font-sm text-white"></i></Link>
                  <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Start Community</h4>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <form>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Community
                            Tag</label>
                          <input type="text"
                            className={`form-control ${errors.tag?.message !== undefined && 'is-invalid'}`}
                            {...register('tag')}/>
                          {errors.tag?.message &&
                              <div className="invalid-feedback text-red font-xsss fw-500 ">
                                {errors.tag?.message}
                              </div>}
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Community
                            Name</label>
                          <input type="text"
                            className={`form-control ${errors.name?.message !== undefined && 'is-invalid'}`}
                            {...register('name')}/>
                          {errors.name?.message &&
                              <div className="invalid-feedback text-red font-xsss fw-500 ">
                                {errors.name?.message}
                              </div>}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">Community
                            Type</label> <br/>
                          <Select
                            options={communityTypes}
                            value={communityTypes.find((t) => t.value === getValues('type'))}
                            onChange={(newValue) => {
                              setValue('type', newValue.value);
                            }}
                            className="react-select react-fix"
                            classNamePrefix="react-select"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label
                            className="mont-font fw-600 font-xsss mb-2 text-dark">Biography</label>
                          <textarea
                            className={`form-control mb-0 p-3 h100 bg-greylight lh-16 ${errors.description && 'is-invalid'}`}
                            rows={5} maxLength={300} placeholder="Write your message..." {...register('description')}/>
                          <div className="invalid-feedback text-red font-xsss fw-500 ">
                            {errors.description?.message}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <Button onClick={handleSubmit(submit)} loading={loading} size="lg">Create Community</Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <Popupchat/>
      <Appfooter/>
    </RequireUser>
  );
}
