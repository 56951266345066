import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const eventsApi = createApi({
  reducerPath: 'events/eventsApi',
  baseQuery: fetchBaseQuery({baseUrl: process.env.REACT_APP_API_URL + '/event', credentials: 'include'}),
  tagTypes: ['Event'],
  endpoints: (builder) => ({
    createEvent: builder.mutation<void, void>({
      query: (body) => ({
        url: '/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Event'],
    }),
  }),
});

export const {
  useCreateEventMutation,
} = eventsApi;
