import {Fragment, useState} from 'react';
import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Rightchat from '../../components/Rightchat';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';
import Popupchat from '../../components/Popupchat';
import {BsFillCloudHazeFill} from 'react-icons/bs';
import {Link} from 'react-router-dom';
import FollowRequestButtons from '../../components/FollowRequestButtons';
import {useUser} from '../../helpers/auth-context';
import RequireUser from '../../components/RequireUser';
import axios from 'axios';
import useSWR from 'swr';
import Loading from '../../components/Loading';
import {useParams} from 'react-router-dom';
import NotAllowed from '../NoPermission';
import RightMessages from '../../components/header/RightMessages';
import Button from '../../components/Button';
import {AlertProps, useAlert} from '../../helpers/alert-provider';


export default function PendingVerifications() {
  const {setAlert} = useAlert();
  const [loading, setLoading] =useState<String[]>([]);

  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };
  const {data, mutate} = useSWR(`${process.env.REACT_APP_API_URL}/admin/verifications`, fetcher);
  if (!data) return <Loading/>;
  if (data.status != 200) return <NotAllowed/>;

  const verifications = data.data;
  console.log(verifications);

  function handleReview(account: any) {
    setLoading((old) => [...old, account.id]);

    let username = '';

    const alert: AlertProps = {
      title: 'Review Account',
      childNode: <>
        <div className="form-group mb-3">
          <label className="mont-font fw-600 font-xsss mb-1 mt-1">Username</label>
          <input
            type="text" className="form-control" onChange={(e) => username = e.target.value}/>
          <div className="invalid-feedback text-red font-xsss fw-500"></div>
        </div>
      </>,
      onConfirm: () => {
        axios.post(`${process.env.REACT_APP_API_URL}/admin/verification/${account.id}`, {username}, {withCredentials: true})
            .then(() => mutate())
            .then(() => setLoading((old) => old.filter((id) => id !== account.id)));
      },
      onClose: () => setLoading((old) => old.filter((id) => id !== account.id)),
    };

    setAlert(alert);
  }

  function handleDelete(account: any) {
    setLoading((old) => [...old, account.id]);

    const alert: AlertProps = {
      title: 'Delete Account',
      childNode: <p>Are you sure you want to delete this account?</p>,
      onConfirm: () => {
        axios.delete(`${process.env.REACT_APP_API_URL}/admin/verification/${account.id}`, {withCredentials: true})
            .then(() => mutate())
            .then(() => setLoading((old) => old.filter((id) => id !== account.id)));
      },
      onClose: () => setLoading((old) => old.filter((id) => id !== account.id)),
    };

    setAlert(alert);
  }

  return (
    <Fragment>
      <Header/>
      <RightMessages/>

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <Pagetitle title={`Pending Verifications`}/>
                <div className="row ps-2 pe-2">
                  {verifications?.length > 0 ? verifications.map((account, index) => (
                    <div key={index} className="col-md-4 col-sm-6 col-12 pe-2 ps-2">
                      <div
                        className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                        <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-left">
                          <p className="fw-500 font-xsss text-grey-500 mt-1 mb-0"><span className="text-grey-700 text-dark-white">Username: </span>{account.user.username} </p>
                          <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3"><span className="text-grey-700 text-dark-white">Phonenumber: </span>{account.phonenumber}</p>
                          <div className="flex-row d-flex">
                            <Button
                              onClick={() => handleDelete(account)}
                              className={'mt-0 btn py-2 px-3 lh-24 mx-1 ls-3 d-inline-block rounded-xl bg-danger font-xsssss fw-700 ls-lg text-white flex-1'}
                              loadingSize={16}
                              disabled={loading.includes(account.id)}
                              // loading={accepting}
                            // spanClassName={acceptSpanClassName}
                            >Delete</Button>
                            <Button
                              onClick={() => handleReview(account)}
                              className={'mt-0 btn py-2 px-3 lh-24 mx-1 ls-3 d-inline-block rounded-xl bg-warning font-xsssss fw-700 ls-lg text-white flex-1'}
                              loadingSize={16}
                              disabled={loading.includes(account.id)}
                            // loading={accepting}
                            // spanClassName={acceptSpanClassName}
                            >Review</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )) : <div className="col-md-12 col-sm-12 pe-2 ps-2">
                    <div
                      className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                      <div
                        className="card-body d-block w-100 ps-3 pe-3 pb-0 text-center text-grey-500">
                        <BsFillCloudHazeFill className="" size={52}/>
                        <div className="clearfix w-100"></div>
                        <p>There are no pending requests...</p>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Appfooter/>

    </Fragment>
  );
}
