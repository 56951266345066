import {Link} from 'react-router-dom';
import {CSSProperties} from 'react';
import axios from 'axios';
import useSWR from 'swr';

const API_URL = process.env.REACT_APP_API_URL;

export type SearchResultsProps = {
    query: string;
    className?: string;
    style?: CSSProperties;
}

export default function SearchResults({query, className, style} : SearchResultsProps): JSX.Element {
  const fetcher = async (url) => {
    if (query === '') return {data: []};
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };

  const {data} = useSWR(`${process.env.REACT_APP_API_URL}/search/profile?query=${query}`, fetcher);

  return (
    <div className={`card w-100 shadow-xss rounded-xxl border-0 mb-3 pt-3 scroll-bar ${className}`}
      style={{...style,
        maxHeight: 'calc(100vh - 200px)'}}
      onClick={(e) => e.stopPropagation()}>
      {query == '' &&
            <div className="card-body d-flex pt-0 ps-4 pe-4 pb-3 bor-0">
                start typing to search
            </div>
      }
      {data?.data && data.data.length > 0 && data.data.map((profile, index) => (
        <div className="wrap" key={index}>
          <Link to={`/p/${profile.tag}`} className="card-body hover-gray d-flex pt-0 ps-4 pe-4 pb-0 bor-0">
            <figure className="avatar me-3"><img src={`${process.env.REACT_APP_API_URL}/image/${profile.pictureUid}/thumb.webp`} alt="avater" className="shadow-sm rounded-circle w45" /></figure>
            <h4 className="fw-700 text-grey-900 font-xssss mt-1">{profile.name} <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">@{profile.tag}</span></h4>
          </Link>
        </div>
      ))}
      {data?.data && data.data.length == 0 && query != '' &&
            <div className="card-body d-flex pt-0 ps-4 pe-4 pb-3 bor-0">
                no results
            </div>
      }
      {!data?.data &&
            <div className="card-body d-flex pt-0 ps-4 pe-4 pb-3 bor-0">
                    loading...
            </div>
      }
    </div>
  );
}

