import React, {Component, Fragment} from 'react';
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Createpost from '../components/Createpost';
import Load from '../components/Load';
import {Link, useParams} from 'react-router-dom';
import {useUser} from '../helpers/auth-context';
import {useCommunityQuery, useModRequestsQuery} from '../store/community/communityApi';
import Notfound from './Notfound';
import CommunityCard from '../components/CommunityCard';
import RightMessages from '../components/header/RightMessages';
import {Community, CommunityMember, CommunityRequest} from '@prisma/client';
import CommunityPostList from '../components/CommunityPostList';
import RequireUser from '../components/RequireUser';
import Pagetitle from '../components/Pagetitle';


export default function ModeratingCommunities() {
  const {user} = useUser();
  const {data: modData} = useModRequestsQuery();

  if (!user) return <RequireUser> </RequireUser>;

  const memberships: (CommunityMember & {community: Community})[] = (user?.activeProfile?.profile?.communities || []).filter((m) => ['ADMIN', 'MODERATOR', 'OWNER'].includes(m.role));

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <RightMessages />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <Pagetitle title="Joined Communities"/>

                <div className="row ps-2 pe-1">
                  {(memberships).map(({community, ...membership}, index) => {
                    const data = modData?.find((m) => m.id === community.id);

                    return (
                      <div key={index} className="col-md-6 col-sm-6 pe-2 ps-2">
                        <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-2">
                          <div className="card-body position-relative h100 bg-image-cover bg-image-center" style={{backgroundImage:
                                  community.bannerUid ?
                                      `url("${process.env.REACT_APP_API_URL}/image/${community.bannerUid}/thumb.webp")` :
                                      `url("${process.env.REACT_APP_API_URL}/image/${community.imageUid}/thumb.webp")`,
                          }}></div>
                          <div className="card-body d-block w-100 pl-10 pe-4 pb-4 pt-0 text-left position-relative">
                            <figure className="avatar position-absolute w75 z-index-1 left-15" style={{marginTop: `-40px`}}><img src={`${process.env.REACT_APP_API_URL}/image/${community.imageUid}/thumb.webp`} alt="avater" className="float-right p-1 bg-card rounded-circle w-100 " /></figure>
                            <div className="clearfix"></div>
                            <h4 className="fw-700 font-xsss mt-3 mb-1">{community.name}</h4>
                            <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-3 lh-3">@{community.tag}</p>
                            <span className="position-absolute right-15 top-0 d-flex align-items-center">
                              <Link to={`/c/${community.tag}/requests`} className="text-center p-2 lh-24 w100 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white">Requests</Link>
                            </span>
                          </div>
                          <div className="bg-card p-2 position-absolute z-index-1 fw-600 text-dark-white text-dark rounded-2" style={{top: 5, right: 5}}>
                            {data?._count.requests} Requests
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* <Load />*/}


                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </Fragment>
  );
}
