import {FiX} from 'react-icons/fi';
import {FaCommentDots, FaCrown, FaEllipsisH, FaUserAlt, FaWrench} from 'react-icons/fa';
import {useUser} from '../../helpers/auth-context';
import {useState} from 'react';
import axios from 'axios';
import {MessageGroupMember, MessageGroupRole} from '@prisma/client';
import {ProfileInfo} from '../../api-types';

function getRoleIcon(role) {
  switch (role) {
    case 'OWNER':
      return (<FaCrown/>);
    case 'ADMIN':
      return (<FaWrench/>);
    default:
      return (<FaUserAlt/>);
  }
}

type MessageMemberRowProps = {
  member: MessageGroupMember & ProfileInfo;
  role: MessageGroupRole,
  onUpdate?: () => void,
  groupId: string
}

export default function MessageMemberRow({member, role, onUpdate = () => {}, groupId}: MessageMemberRowProps) {
  const {user} = useUser();
  const [isOpen, setOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const toggleOpen = () => setOpen(!isOpen);
  const isOwn = member.profileId === user.activeProfile?.profileId;

  function performAction(action: String) {
    setOpen(false);
    if (busy) return;
    setBusy(true);
    axios.patch(`${process.env.REACT_APP_API_URL}/messages/group/${groupId}/member`, {profile: member.profile.tag, action: action}, {withCredentials: true, validateStatus: () => true})
        .then((res) => {
          onUpdate();
          setBusy(false);
        });
  }

  return <li key={member.profileId}>
    <div
      className={`profile-entry rounded-3`}>
      <div className="profile-user">
        <img
          src={`${process.env.REACT_APP_API_URL}/image/${member.profile.pictureUid}/thumb.webp`}
          alt="user"
          className="w35 me-2"/>
        <div style={{lineHeight: '12px'}}>
          <h6 className="font-xssss text-grey-900 mb-0 mt-0 fw-700">{member.profile.name}</h6>
          <span
            className="font-xssss text-grey-900">@{member.profile.tag}</span>
        </div>
      </div>
      <div
        className="email-subject text-grey-900 text-dark font-xssss me-5">
        {isOwn && <i>You</i>}
      </div>
      <div
        className="email-subject text-grey-900 text-dark fw-600 font-xssss">
        {member.role !== 'MEMBER' && <><span
          className="font-sm me-2">{getRoleIcon(member.role)}</span>
        {`${member.role[0].toUpperCase()}${member.role.slice(1).toLowerCase()}`} </>}
      </div>
      <div className="ms-auto position-relative">
        {role !== 'MEMBER' && member.role !== 'OWNER' && !(member.role == role) &&
          <div className="cursor-pointer" onClick={toggleOpen}>
            <FaEllipsisH className="text-grey-900 text-dark"/>
          </div>
        }
        <div className={`dropdown-menu p-2 right-0 rounded-xxl border-0 shadow-lg ${isOpen && !busy ? 'show' : ''}`}>
          {role === 'OWNER' && <div className="border-0 card pt-2 bg-transparent-card w-100"><a
            className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block hover-gray cursor-pointer" onClick={() => performAction(member.role === 'ADMIN' ? 'DEMOTE': 'PROMOTE')}>{member.role === 'ADMIN' ? 'Remove as Admin' : 'Make Admin'}</a></div>}
          <div className="border-0 card pt-2 bg-transparent-card w-100"><a
            className="font-xsss text-danger mb-1 mt-0 fw-700 d-block hover-gray cursor-pointer" onClick={() => performAction('KICK')}>Kick from Group</a></div>
        </div>
      </div>
    </div>
  </li>;
}
