import {EarnedProfileBadge, ProfileBadge as BadgeType} from '@prisma/client';
import {Icon} from '@iconify/react';
import Tippy from '@tippyjs/react';

export default function ProfileBadge({badge, className}: {
    badge: EarnedProfileBadge & { badge: BadgeType },
    className?: string
}) {
  return <Tippy content={<><span>{badge.badge.description}</span></>} placement={'top'}>
    <span className={`profile-badge ${className}`} style={{lineHeight: 0}}>
      <Icon color={badge.badge.color}
        icon={badge.badge.icon}/>
    </span>
  </Tippy>;
}
