import React from 'react';


export default function PrivateProfileCard() {
  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 text-center">
      <div className="card-body d-block p-4">
        <h4 className="fw-700 mb-1 font-xsss text-grey-900">Private Profile</h4>
        <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">This profile is private and only followers can view this profile</p> <br />
        <i className="feather-lock text-grey-500 m-3" style={{fontSize: '62px'}}></i>
      </div>
    </div>
  );
}
