import Button from './Button';
import {useState} from 'react';
import axios from 'axios';

type FollowRequestButtonsProps = {
    tag: string;
    acceptClassName?: string;
    ignoreClassName?: string;
    acceptSpanClassName?: string;
    ignoreSpanClassName?: string;
}

export default function FollowRequestButtons({tag, acceptClassName, ignoreClassName, acceptSpanClassName, ignoreSpanClassName}: FollowRequestButtonsProps) {
  const [accepting, setAccepting] = useState(false);
  const [ignoring, setIgnoring] = useState(false);
  const [done, setDone] = useState(undefined);
  const loading = accepting || ignoring;

  function handleButton(accepted: boolean) {
    if (loading) return;
    if (accepted) setAccepting(true);
    else setIgnoring(true);
    axios.post(`${process.env.REACT_APP_API_URL}/profile/${tag}/follow`, {action: accepted ? 'ACCEPT' : 'IGNORE'}, {withCredentials: true})
        .then(async (res) => {
          setDone(accepted ? 'accepted' : 'ignored');
          if (accepted) setAccepting(false);
          else setIgnoring(false);
        })
        .catch((err) => {
          if (accepted) setAccepting(false);
          else setIgnoring(false);
        });
  }

  if (done === undefined) {
    return <>
      <Button
        onClick={() => handleButton(true)}
        className={acceptClassName}
        loadingSize={16}
        disabled={loading}
        loading={accepting}
        spanClassName={acceptSpanClassName}
      >Accept</Button>
      <Button
        onClick={() => handleButton(false)}
        loadingSize={16}
        disabled={loading}
        loading={ignoring}
        className={ignoreClassName}
        spanClassName={ignoreSpanClassName}>Ignore</Button>
    </>;
  } else {
    return <p className="font-xssss">Successfully {done} request!</p>;
  }
}
