import {Button as BootstrapButton, ButtonProps as BootstrapButtonProps} from 'react-bootstrap';
import {MoonLoader} from 'react-spinners';
import {ReactNode} from 'react';
import {Icon} from '@iconify/react';

type ButtonProps = {
  children?: ReactNode;
  loading?: boolean;
  className?: string;
  loadingSize?: number;
  loadingColor?: string;
  spanClassName?: string;
} & BootstrapButtonProps;

export default function Button({children, loading, className, loadingSize = 25, loadingColor, spanClassName, ...rest}: ButtonProps) {
  return <BootstrapButton className={`position-relative border-0 ${className} ${loading ? 'no-pointer-events' : ''}`} {...rest}>
    {loading && <Icon icon={'line-md:loading-twotone-loop'} fontSize={loadingSize} className={'position-absolute'} style={{top: 0, right: 0, left: 0, bottom: 0, margin: 'auto'}}/>}
    <span style={{visibility: loading ? 'hidden' : 'inherit'}} className={spanClassName}>{children}</span>
  </BootstrapButton>;
}
