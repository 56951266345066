import {Fragment} from 'react';
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Pagetitle from '../components/Pagetitle';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import {BsFillCloudHazeFill} from 'react-icons/bs';
import {Link} from 'react-router-dom';
import FollowRequestButtons from '../components/FollowRequestButtons';
import {useUser} from '../helpers/auth-context';
import RequireUser from '../components/RequireUser';
import axios from 'axios';
import useSWR from 'swr';
import Loading from '../components/Loading';
import {useParams} from 'react-router-dom';
import NotAllowed from './NoPermission';
import RightMessages from '../components/header/RightMessages';


export default function FollowerList() {
  const {tag} = useParams<{tag: string}>();
  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };
  const {data} = useSWR(`${process.env.REACT_APP_API_URL}/profile/${tag}/followers`, fetcher);
  if (!data) return <Loading/>;
  if (data.status != 200) return <NotAllowed/>;

  const followers = data.data;

  return (
    <Fragment>
      <Header/>
      <Leftnav/>
      <RightMessages/>

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <Pagetitle title={`Followers @${tag}`}/>

                <div className="row ps-2 pe-2">
                  {followers.length > 0 ? followers.map((profile, index) => (
                    <div key={index} className="col-md-4 col-sm-6 col-12 pe-2 ps-2">
                      <div
                        className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                        <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                          <Link to={`/p/${profile.tag}`}>
                            <figure
                              className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1">
                              <img
                                height={65}
                                width={65}
                                src={`${process.env.REACT_APP_API_URL}/image/${profile.pictureUid}/thumb.webp`}
                                alt="avatar"
                                className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"/>
                            </figure>
                            <div className="clearfix w-100"></div>
                            <h4 className="fw-700 font-xsss mt-3 mb-0">{profile.name} </h4>
                            <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">@{profile.tag}</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )) : <div className="col-md-12 col-sm-12 pe-2 ps-2">
                    <div
                      className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                      <div
                        className="card-body d-block w-100 ps-3 pe-3 pb-0 text-center text-grey-500">
                        <BsFillCloudHazeFill className="" size={52}/>
                        <div className="clearfix w-100"></div>
                        <p>@{tag} doesn&#39;t have any followers...</p>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Appfooter/>

    </Fragment>
  );
}
