import {Profile, Event} from '@prisma/client';
import React from 'react';
import {Icon} from '@iconify/react';

export default function EventCard({event} : {event: (Event & {profile: Profile})}) {
  const month = new Date(event.startDate).toLocaleString('default', {month: 'short'});
  const day = new Date(event.startDate).getDate();

  return <div className="col-lg-4 col-md-6 pe-2 ps-2">
    <div
      className="card p-3 bg-white w-100 hover-card border-0 shadow-xss rounded-xxl border-0 mb-3 overflow-hidden ">
      <div className="card-image w-100">
        <img src={`${process.env.REACT_APP_API_URL}/image/${event.imageUid}/thumb.webp`} alt="event" className="w-100 rounded-3"/>
      </div>
      <div className="card-body d-flex ps-0 pe-0 pb-0">
        <div className="bg-greylight me-3 p-3 rounded-xxl theme-dark-bg"><h4
          className="fw-700 font-lg ls-3 text-grey-900 mb-0"><span
            className="ls-3 d-block font-xsss text-grey-500 fw-500">{month}</span>{day}
        </h4></div>
        <h2 className="fw-700 lh-3 font-xss">{event.name}
          <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500 align-items-center"> <i
            className="ti-location-pin me-1"></i>{event.location} </span>
          <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500 align-items-center"> <Icon icon="ph:at-bold" className="me-1"/>{event.profile.tag} </span>
        </h2>
      </div>
      {/* <div className="card-body p-0">*/}
      {/*  <ul className="memberlist mt-4 mb-2 ms-0 d-inline-block">*/}
      {/*    <li><a href="/defaultevent"><img src="assets/images/user.png" alt="user"*/}
      {/*      className="w30 d-inline-block"/></a></li>*/}
      {/*    <li><a href="/defaultevent"><img src="assets/images/user.png" alt="user"*/}
      {/*      className="w30 d-inline-block"/></a></li>*/}
      {/*    <li><a href="/defaultevent"><img src="assets/images/user.png" alt="user"*/}
      {/*      className="w30 d-inline-block"/></a></li>*/}
      {/*    <li><a href="/defaultevent"><img src="assets/images/user.png" alt="user"*/}
      {/*      className="w30 d-inline-block"/></a></li>*/}
      {/*    <li className="last-member"><a href="/defaultevent"*/}
      {/*      className="bg-greylight fw-600 text-grey-500 font-xssss ls-3 text-center">+2</a>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*  <a href="/defaultevent"*/}
      {/*    className="font-xsssss fw-700 ps-3 pe-3 lh-32 float-right mt-4 text-uppercase rounded-3 ls-2 bg-success d-inline-block text-white me-1">APPLY</a>*/}
      {/* </div>*/}
    </div>
  </div>;
}
