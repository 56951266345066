import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {Community, CommunityMember, CommunityRequest, CommunityRole, Image} from '@prisma/client';
import {reduxRTKInfiniteScroll} from '../../helpers/utils';

export const communityApi = createApi({
  reducerPath: 'community/communityApi',
  baseQuery: fetchBaseQuery({baseUrl: process.env.REACT_APP_API_URL + '', credentials: 'include'}),
  tagTypes: ['Popular'],
  endpoints: (builder) => ({
    createCommunity: builder.mutation<{
      success: boolean,
      community: Community
    }, Omit<Community, 'imageUid' | 'id' | 'bannerUid' | 'groupId'>>({
      query: (body) => ({
        url: `community/create`,
        method: 'POST',
        body,
      }),
    }),
    popularCommunities: builder.query<(Community & { _count: { members: number, posts: number } })[], {
      page: number,
      amount: number
    }>({
          query: ({page, amount}) => `feeds/popular-communities?take=${amount}&skip=${page * amount}`,
          ...reduxRTKInfiniteScroll({equalFn: (a, b) => a.tag === b.tag}),
        }),
    modRequests: builder.query<{ name: string, tag: string, id: string, _count: { requests: number } }[], void>({
      query: () => `feeds/communities-total-mod-requests`,
    }),
    community: builder.query<(Community & { _count: { members: number, posts: number } }), string>({
      query: (id) => `community/${id}`,
    }),
    joinCommunity: builder.mutation<void, string>({
      query: (tag) => ({
        url: `community/${tag}/join`,
        method: 'POST',
      }),
    }),
    communityRequests: builder.query<(CommunityRequest & {
      profile: { tag: string, pictureUid: string, name: string }
    })[], string>({
          query: (tag) => `community/${tag}/requests`,
        }),
    answerRequest: builder.mutation<void, { id: string, tag: string, action: 'ACCEPT' | 'REJECT' }>({
      query: ({id, tag, action}) => ({
        url: `community/${tag}/request/${id}/answer`,
        method: 'POST',
        body: {action},
      }),
    }),
    leaveCommunity: builder.mutation<void, string>({
      query: (tag) => ({
        url: `community/${tag}/leave`,
        method: 'POST',
      }),
    }),
    updateCommunity: builder.mutation<void, Omit<Community, 'id' | 'groupId'>>({
      query: (body) => ({
        url: `community/${body.tag}`,
        method: 'PATCH',
        body,
      }),
    }),
    communityMembers: builder.query<(CommunityMember & {
      profile: { tag: string, name: string, pictureUid: string }
    })[], string>({
          query: (tag) => `community/${tag}/members`,
        }),
    communityModerators: builder.query<(CommunityMember & {
      profile: { tag: string, name: string, pictureUid: string }
    })[], string>({
          query: (tag) => `community/${tag}/moderators`,
        }),
    communityMemberRole: builder.mutation<void, {
      tag: string,
      role: CommunityRole,
      profileId: string
    }>({
      query: ({tag, role, profileId}) => ({
        url: `community/${tag}/member/${profileId}/role`,
        method: 'PATCH',
        body: {role},
      }),
    }),
  }),
});

export const {
  useCreateCommunityMutation,
  usePopularCommunitiesQuery,
  useCommunityQuery,
  useJoinCommunityMutation,
  useCommunityRequestsQuery,
  useAnswerRequestMutation,
  useLeaveCommunityMutation,
  useModRequestsQuery,
  useUpdateCommunityMutation,
  useCommunityMembersQuery,
  useCommunityModeratorsQuery,
  useCommunityMemberRoleMutation,
} = communityApi;
