import {Component, Fragment, useState} from 'react';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import axios from 'axios';
import useSWR from 'swr';
import Loading from '../components/Loading';
import {useGetAdvertiserAccountQuery, useOpenAccountMutation} from '../store/ads/adsApi';
import PostCard from '../components/PostCard';
import Button from '../components/Button';
import Chart from 'react-apexcharts';
import {Icon} from '@iconify/react';
import Campaigns from '../components/ads/Campaigns';
import {useUser} from '../helpers/auth-context';
import NotAllowed from './NoPermission';
import {AdvertiserAccount} from '@prisma/client';

export default function AdAdmin() {
  const {user} = useUser();
  const [advertiserQuery, setAdvertiserQuery] = useState('');
  const [advertiser, setAdvertiser] = useState<AdvertiserAccount>(null);
  const [credits, setCredits] = useState(0);

  const [loadingAdvertiser, setLoadingAdvertiser] = useState(false);
  const [loadingCredits, setLoadingCredits] = useState(false);

  if (!user?.staff) {
    return <NotAllowed/>;
  }

  function lookupAdvertiser() {
    setLoadingAdvertiser(true);
    axios.get(`${process.env.REACT_APP_API_URL}/admin/advertiser/${advertiserQuery}`, {withCredentials: true}).then((res) => {
      setAdvertiser(res.data);
      setCredits(res.data.credits);
    }).finally(() => setLoadingAdvertiser(false));
  }

  function saveCredits() {
    setLoadingCredits(true);
    axios.post(`${process.env.REACT_APP_API_URL}/admin/advertiser/${advertiser.id}/credits`, {credits}, {withCredentials: true}).then(() => {
      setCredits(0);
      setAdvertiser(null);
      setAdvertiserQuery('');
    }).finally(() => setLoadingCredits(false));
  }

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content bg-white right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="card w-100 border-0 shadow-none p-4 rounded-xxl bg-lightblue2 mb-3">
                  <div className="row">
                    <h2 className="d-block mb-2 text-grey-900 fw-700">Ad Credits</h2>
                    <div className="form-group">
                      <div className="row">
                        <label className="mont-font fw-600 font-xsss mb-2">Advertiser Tag</label>

                        <div className="col-lg-8 mb-3">
                          <input type="text" className="form-control" value={advertiserQuery} onChange={(e) => setAdvertiserQuery(e.target.value)}/>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <Button size="lg" className="h-100 w-100" onClick={lookupAdvertiser} loading={loadingAdvertiser}>Lookup</Button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <label className="mont-font fw-600 font-xsss mb-2">Credits</label>

                        <div className="col-lg-4 mb-3">
                          <input type="number" className="form-control" value={credits} onChange={(e) => setCredits(Number(e.target.value))} disabled={advertiser === null}/>
                        </div>
                        <div className="col-lg-8 mb-3">
                          <Button size="lg" className="h-100 w-100" loading={loadingCredits} disabled={advertiser === null} onClick={saveCredits}>{advertiser === null ? 'No User' : 'Set Credits'}</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat/>
      <Appfooter/>
    </Fragment>
  );
}

