import {Event} from '@prisma/client';

export default function EventsDetail({events}: {events: Event[]}) {
  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-flex align-items-center p-4">
        <h4 className="fw-700 mb-0 font-xssss text-grey-900">Events</h4>
        <a href="/events" className="fw-600 ms-auto font-xssss text-primary">See all</a>
      </div>
      {events.map((value, index) => {
        const month = new Date(value.startDate).toLocaleString('default', {month: 'short'});
        const day = new Date(value.startDate).getDate();


        return (
          <div key={index} className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden">
            <div className={`bg-success me-2 p-3 rounded-xxl bg-primary-gradiant`}><h4 className="fw-700 font-lg ls-3 lh-1 text-white mb-0"><span className="ls-1 d-block font-xsss text-white fw-600">{month}</span>{day}</h4></div>
            <h4 className="fw-700 text-grey-900 font-xssss mt-2">{value.name} <span className="d-block font-xsssss fw-500 mt-1 lh-4 text-grey-500">{value.location}</span> </h4>
          </div>

        );
      })}


    </div>
  );
}
