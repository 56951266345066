import {Link, useHistory, useParams} from 'react-router-dom';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import RequireUser from '../components/RequireUser';
import {useUser} from '../helpers/auth-context';
import PageAlert from '../components/PageAlert';
import Select from 'react-select';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import RightMessages from '../components/header/RightMessages';
import Button from '../components/Button';
import {
  useCommunityQuery,
  useCreateCommunityMutation,
  useUpdateCommunityMutation,
} from '../store/community/communityApi';
import {Community, CommunityMember, CommunityType} from '@prisma/client';
import {useForm} from 'react-hook-form';
import Notfound from './Notfound';
import Load from '../components/Load';
import NotAllowed from './NoPermission';
import {Icon} from '@iconify/react';

const API_URL = process.env.REACT_APP_API_URL;

export default function EditCommunity() {
  const {user, reloadUser} = useUser();
  const {tag} = useParams<{tag: string}>();
  const {data: community, isError} = useCommunityQuery(tag);
  const [updateCommunity, {isLoading: submitting}] = useUpdateCommunityMutation();


  const [uploadingProfilePic, setUploadingProfilePic] = useState(false);
  const [uploadingBannerPicture, setUploadingBannerPicture] = useState(false);
  const [bannerFile, setBannerFile] = useState<string | undefined>(undefined);

  // Field errors
  const communityTypes = [
    {value: CommunityType.PUBLIC, label: 'Open'},
    {value: CommunityType.INVITE_ONLY, label: 'Visible (Request to Join)'},
    {value: CommunityType.PRIVATE, label: 'Private'},
  ];

  const {register, handleSubmit, setValue, getValues, setError, formState: {errors}} = useForm<Omit<Community, 'id' | 'groupId'>>();

  useEffect(() => {
    if (community) {
      setValue('name', community.name);
      setValue('description', community.description);
      setValue('type', community.type);
      setValue('imageUid', community.imageUid);
      setValue('bannerUid', community.bannerUid);
      setValue('tag', community.tag);
      setBannerFile(community.bannerUid);
    }
  }, [community]);

  if (!user) {
    return (
      <RequireUser> </RequireUser>
    );
  }
  if (isError) return <Notfound />;
  if (!community) return (<Load></Load>);
  const membership:CommunityMember & {community: Community} | undefined = user?.activeProfile?.profile?.communities?.find((m:CommunityMember) => m.communityId === community.id);

  if (!membership || !['ADMIN', 'OWNER'].includes(membership.role)) {
    return (
      <NotAllowed/>
    );
  }


  function uploadProfilePicture(imageBlob, filename) {
    const formData = new FormData();
    formData.set('image', imageBlob, filename);
    formData.set('type', 'profile_picture');
    setUploadingProfilePic(true);
    axios.post(`${API_URL}/image/upload`, formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
        .then((res) => {
          if (res.data.files.length > 0) {
            setValue('imageUid', res.data.id);
          }
          setUploadingProfilePic(false);
        })
        .catch((err) => {
          setError('imageUid', err.message);
          setUploadingProfilePic(false);
        });
  }

  function uploadBannerPicture(imageBlob) {
    const formData = new FormData();
    formData.set('image', imageBlob, imageBlob.name);
    formData.set('type', 'banner_picture');
    setUploadingBannerPicture(true);
    axios.post(`${API_URL}/image/upload`, formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
        .then((res) => {
          if (res.data.files.length > 0) {
            setValue('bannerUid', res.data.id);
            setBannerFile(imageBlob.name);
          }
          setUploadingBannerPicture(false);
        })
        .catch((err) => {
          setError('bannerUid', err.message);
          setUploadingBannerPicture(false);
        });
  }


  function submit(community: Omit<Community, 'id' | 'groupId'>) {
    updateCommunity(community).unwrap()
        .then((res) => {
          window.location.href = '/c/'+community.tag;
        })
        .catch((error) => {
          setError('tag', {message: error.data.message});
        });
  }

  return (
    <RequireUser>
      <Header/>
      <Leftnav/>
      <RightMessages />

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active">

        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                  <Link to={'/c/'+community.tag} className="d-inline-block mt-2"><i
                    className="ti-arrow-left font-sm text-white"></i></Link>
                  <h4 className=" font-xs text-white fw-600 ms-4 mb-0 mt-2">Community Details</h4>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <div className="row justify-content-center">
                    <div className="col-lg-4 text-center form-group">
                      <input type="file" name="profile-picture" id="profile-picture" accept="image/*"
                        className="input-file"
                        onChange={(e) => {
                          uploadProfilePicture(e.target.files[0], e.target.files[0].name);
                        }}/>
                      <label htmlFor="profile-picture" className="cursor-pointer">
                        <figure
                          className={`avatar ms-auto me-auto mb-0 mt-2 w100 ${uploadingProfilePic && 'img-loading'}`}
                          style={{position: 'relative', height: '100px', width: '100px'}}>
                          <div className="loader"/>
                          <img
                            src={`${API_URL}/image/${getValues('imageUid')}/thumb.webp`}
                            alt="avatar"
                            className="shadow-sm rounded-3 w-100"/>
                        </figure>
                      </label>
                      <h2 className="fw-700 font-sm text-grey-900 mt-3">{community.name}</h2>
                      <h4 className="text-grey-500 fw-500 mb-3 font-xsss mb-4">@{community.tag}</h4>

                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">Community Tag</label>
                        <input type="text" className="form-control" disabled
                          value={community.tag}/>
                      </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">Community Name</label>
                        <input type="text" className={`form-control ${errors.name && 'is-invalid'}`} {...register('name')}/>
                        <div className="invalid-feedback text-red font-xsss fw-500 ">
                          {errors.name?.message}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">Profile
                            Type</label> <br/>
                        <div className="d-flex flex-row">
                          <>
                            <Select
                              options={communityTypes}
                              defaultValue={communityTypes.find((a) => a.value === getValues('type'))}
                              onChange={(newValue) => {
                                setValue('type', newValue.value);
                              }}
                              className="react-select me-1  flex-1"
                              classNamePrefix="react-select"
                            />
                          </>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 mb-3">
                    <label
                      className="mont-font fw-600 font-xsss mb-2 text-dark">Description</label>
                    <textarea className={`form-control mb-0 p-3 h100 bg-greylight lh-16 ${errors.description && 'is-invalid'}`}
                      rows={5} maxLength={300} placeholder="Write your message..." {...register('description')}></textarea>
                    <div className="invalid-feedback text-red font-xsss fw-500 ">
                      {errors.description?.message}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="card mt-3 border-0">
                        <div
                          className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                            <label
                              className="mont-font fw-600 font-xsss mb-2 text-dark">Banner
                                Picture</label>
                            <input type="file" name="banner-picture" id="banner-picture"
                              className="input-file" onChange={(e) => {
                                uploadBannerPicture(e.target.files[0]);
                              }}/>
                            <label htmlFor="banner-picture"
                              className={`rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed ${errors.bannerUid && 'is-invalid '}`} >
                              {uploadingBannerPicture ? <>
                                <Icon icon={'line-md:upload-loop'} className="large-icon me-auto ms-auto d-block" width={45}/>
                                <span className="js-fileName">{bannerFile ? bannerFile : `Uploading banner...`}</span>
                              </>:
                                <><i className={`${bannerFile ? 'ti-file' : 'ti-cloud-down'} large-icon me-3 d-block`}></i>
                                  <span className="js-fileName">{bannerFile ? bannerFile : `Click to upload a new banner!`}</span></>}
                            </label>
                            <div className="invalid-feedback text-red font-xsss fw-500 ">
                              {errors.bannerUid?.message}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <Button
                        className={`bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block`}
                        loading={submitting}
                        onClick={handleSubmit(submit)}>Save</Button>
                      <div className="invalid-feedback text-red font-xsss fw-500 ">

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <Popupchat/>
      <Appfooter/>
    </RequireUser>
  );
}
