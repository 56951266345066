import {Link, useHistory, useParams} from 'react-router-dom';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import RequireUser from '../components/RequireUser';
import {useUser} from '../helpers/auth-context';
import PageAlert from '../components/PageAlert';
import Select from 'react-select';
import {useEffect, useState} from 'react';
import axios from 'axios';
import RightMessages from '../components/header/RightMessages';
import Button from '../components/Button';
import {OverlayTrigger, Popover, Tooltip} from 'react-bootstrap';
import NotAllowed from './NoPermission';
import {useCreateCampaignMutation, useGetAdvertiserAccountQuery, usePatchCampaignMutation} from '../store/ads/adsApi';
import Loading from '../components/Loading';
import {useForm} from 'react-hook-form';
import {AdvertismentCampaign} from '@prisma/client';
import SearchPostResults from '../components/ads/SearchPostResults';
import PostCard from '../components/PostCard';

function EditCampaign() {
  const {campaignId} = useParams<{ campaignId?:string }>();
  const creating = campaignId === undefined;
  const {user, reloadUser} = useUser();
  const {data: account, isLoading} = useGetAdvertiserAccountQuery();
  const [createCampaign, {data, error, isLoading: isCreating}] = useCreateCampaignMutation();
  const [patchCampaign, {data: patchData, error: patchError, isLoading: isPatching}] = usePatchCampaignMutation();
  const [searchOpen, setSearchOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [posts, setPosts] = useState<any[]>([]);
  const {register, handleSubmit, setValue} = useForm<Omit<AdvertismentCampaign, 'id' | 'accountId' | 'startDate' | 'endDate'>>({defaultValues: {}});
  const campaign = creating ? null : account?.campaigns.find((c) => c.id === campaignId);

  useEffect(() => {
    document.querySelector('body').addEventListener('click', (e) => setSearchOpen(false));
  });
  useEffect(() => {
    const n = posts.reduce((acc, post) => {
      if (acc.find((p) => p.id === post.id)) return acc;
      return [...acc, post];
    }, []);

    if (n.length !== posts.length) setPosts(n);
  }, [posts]);

  useEffect(() => {
    if (campaign) {
      setValue('name', campaign.name);
      setValue('budget', campaign.budget);

      Promise.all(campaign.ads.map(async (post) => await axios.get(`${process.env.REACT_APP_API_URL}/profile/${user.activeProfile.profile.tag}/post/${post.postId}`)))
          .then((res) => {
            setPosts(res.map((r) => r.data));
          });
    }
  }, [campaign]);
  const history = useHistory();

  if (isLoading) return <Loading/>;

  if (account === null) {
    return <NotAllowed/>;
  }


  function save(data: Omit<AdvertismentCampaign, 'id' | 'accountId' | 'startDate' | 'endDate'>) {
    if (posts.length === 0) return alert('You must select at least one post');

    if (creating) {
      createCampaign({
        ...data,
        posts: posts.map((p) => p.id),
      }).unwrap().then(() => {
        history.push('/advertiser');
      });
    } else {
      patchCampaign({
        ...data,
        posts: posts.map((p) => p.id),
        id: campaign.id,
      }).unwrap().then(() => {
        history.push('/advertiser');
      });
    }
  }

  return (
    <RequireUser>
      <Header/>
      <Leftnav/>
      <RightMessages />

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active">

        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                  <Link to={'/advertiser'} className="d-inline-block mt-2"><i
                    className="ti-arrow-left font-sm text-white"></i></Link>
                  <h4 className=" font-xs text-white fw-600 ms-4 mb-0 mt-2">{creating?'New' : 'Manage'} Campaign</h4>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">

                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">Campaign Name</label>
                        <input type="text" className="form-control" {...register('name', {required: true})}/>
                      </div>
                    </div>

                    <div className="col-lg-3 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">Budget (Credits)</label>
                        <input type="number" min={campaign?.spent || 0} max={account.credits}
                          className="form-control" {...register('budget', {required: true})}/>
                      </div>
                    </div>

                    <div className="col-lg-3 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">Spent (Credits)</label>
                        <input type="number" className="form-control" disabled value={campaign?.spent || 0}/>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label className="mont-font fw-600 font-xsss mb-2">Posts</label>
                        <input type="text" className="form-control"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSearchOpen(true);
                          }}
                          placeholder={'Search Posts...'}/>
                        {searchOpen && <SearchPostResults query={search} className="position-absolute" select={(post) => {
                          setPosts((c) => [...c, post]);
                          setSearchOpen(false);
                          setSearch('');
                        }}/>}
                        {posts.map((post) => <div className="cursor-pointer" key={post.id} onClick={(e) => {
                          e.stopPropagation();
                          setPosts((c) => c.filter((p) => p.id !== post.id));
                        }}>
                          <div className="pointer-events-none">
                            <PostCard post={post}/>
                          </div>
                        </div>)}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <Button onClick={handleSubmit(save)} loading={isCreating || isPatching} className="p-3">Save</Button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <Popupchat/>
      <Appfooter/>
    </RequireUser>
  );
}

export default EditCampaign;
