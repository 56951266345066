import React, {Component, Fragment} from 'react';
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import {useUser} from '../helpers/auth-context';

const API_URL = process.env.REACT_APP_API_URL;

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.user = props.user;
    this.reloadUser = props.reloadUser.bind(this);

    this.state = {
      username: '',
      password: '',
      error: {
        no_username: false,
        no_password: false,
        incorrect_password: false,
      },
      loading: false,
      loggedIn: this.user !== null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();


    this.setState({
      error:
                {
                  no_username: this.state.username === '',
                  no_password: this.state.password === '',
                },
    });
    if (this.state.username === '' || this.state.password === '') return;

    this.setState({loading: true});
    const res = await axios.post(`${API_URL}/auth/login`, {
      username: this.state.username,
      password: this.state.password,
    }, {withCredentials: true, validateStatus: () => true})
        .catch((err) => {
          this.setState({loading: false});
        });
    if (res.status === 200) {
      document.location.href = '/';
    } else {
      this.setState({error: {incorrect_password: true}, loading: false});
    }
  }

  render() {
    return (
      <Fragment>
        <div className="main-wrap">
          <div className="nav-header bg-transparent shadow-none border-0">
            <div className="nav-top w-100">
              <a href="/"><img src="/assets/images/REAL_LOGO.svg" alt="logo" style={{height: '50px'}}/><span className="ms-2 d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">onrain </span> </a>
              <button className="nav-menu me-0 ms-auto"></button>

              <a href="/login" className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
              <a href="/register" className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
              style={{backgroundImage: `url("/assets/images/login-side-image.png")`, backgroundPositionY: 'y-end'}}></div>
            <div className="col-xl-7 align-items-center d-flex bg-white rounded-3 centered-card">
              <div className="card shadow-none border-0 ms-auto me-auto login-card">
                <div className="card-body rounded-0 text-left">
                  <h2 className="fw-700 display1-size display2-md-size mb-3">Login into <br />your account</h2>
                  <form onSubmit={this.handleSubmit}>

                    <div className="form-group icon-input mb-3">
                      <i className="font-sm ti-email text-grey-500 pe-0"></i>
                      <input name="username" value={this.state.username} onChange={this.handleInputChange} type="text" className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600 ${(this.state.error.no_username || this.state.error.incorrect_password) && 'is-invalid'}`} placeholder="Your Username" />
                      {this.state.error.no_username && <div className="invalid-feedback text-red font-xsss fw-500 ">
                                                   Please enter your username
                      </div>}
                    </div>
                    <div className="form-group icon-input mb-1">
                      <input name="password" value={this.state.password} onChange={this.handleInputChange} type="Password" className={`style2-input ps-5 form-control text-grey-900 font-xss ls-3 ${(this.state.error.no_password || this.state.error.incorrect_password) && 'is-invalid'}`} placeholder="Password" />
                      <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                      {this.state.error.no_password && <div className="invalid-feedback text-red font-xsss fw-500 ">
                                                    Please enter your password
                      </div>}
                      {this.state.error.incorrect_password && <div className="invalid-feedback text-red font-xsss fw-500 ">
                                                    Username or password is incorrect
                      </div>}
                    </div>
                    <div className="form-check text-left mb-3">
                      <a href="/forgot" className="fw-600 font-xsss text-grey-700 mt-1 float-right">Forgot your Password?</a>
                    </div>
                  </form>

                  <div className="col-sm-12 p-0 text-left">
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    <div className="form-group mb-1"><a href="javascript:void(0)" onClick={this.handleSubmit} className={`${this.state.loading && 'disabled'} form-control text-center style2-input text-white fw-600 bg-dark transition-color border-0 p-0`}>Login</a></div>
                    <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">Dont have account <a href="/register" className="fw-700 ms-1">Register</a></h6>
                  </div>
                  <div className="col-sm-12 p-0 text-center mt-2">

                    <h6 className="mb-0 d-inline-block bg-white fw-500 font-xsss text-grey-500 mb-3">Or, Sign in an other way </h6>
                    {/* <div className="form-group mb-1">*/}
                    {/*  <a href="/login" className="form-control text-left style2-input text-white fw-600 bg-current border-0 p-0 mb-2">*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"*/}
                    {/*      fill="currentColor" className="bi bi-link ms-2 w40 mb-1 me-5" viewBox="0 0 16 16">*/}
                    {/*      <path*/}
                    {/*        d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>*/}
                    {/*      <path*/}
                    {/*        d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"/>*/}
                    {/*    </svg> Get link via Text</a>*/}
                    {/* </div>*/}
                    <div className="form-group mb-1">
                      <a href={`${process.env.REACT_APP_API_URL}/auth/discord`} className="form-control text-left style2-input text-white fw-600 border-0 p-0 mb-2" style={{backgroundColor: '#5865F2'}}>
                        <img className="ms-3 me-5" src={'/assets/images/discord-mark-white.svg'} width={32} height={32} /> Log in with Discord</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Fragment>
    );
  }
}

export default function Login() {
  const {user, reloadUser} = useUser();
  return <LoginPage user={user} reloadUser={reloadUser}/>;
};
