import {FeedEntry} from '../../api-types';
import PostCard from '../PostCard';

type PrimaryFeedEntryProps = {
    entry: FeedEntry
}

export default function PrimaryFeedEntry({entry}:PrimaryFeedEntryProps) {
  switch (entry.type) {
    case 'FEATURED_POST':
    case 'FOLLOWER_POST':
      return <PostCard post={entry.post} />;
    case 'ADVERTISEMENT':
      return <PostCard post={entry.post} advertisement={true} />;
  }
}
