import {Message} from '../../pages/Messages';
import {GroupData, ProfileInfo} from '../../api-types';
import {getRelativeTime} from '../../helpers/utils';
import {useUser} from '../../helpers/auth-context';
import {BsThreeDots} from 'react-icons/bs';
import {useState} from 'react';
import axios from 'axios';

export type ChatMessageProps = {
    message: Message & ProfileInfo & {pending? : boolean, error?: string},
    group: GroupData,
}

export default function ChatMessage({message, group}: ChatMessageProps) {
  const {user} = useUser();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [deleted, setDeleted] = useState<boolean>(message.deleted);
  const toggleMenu = () => setShowMenu(!showMenu);

  async function deleteMessage() {
    setShowMenu(false);
    if (message.deleted) return;
    setDeleted(true);
    await axios.delete(`${process.env.REACT_APP_API_URL}/messages/group/${group.id}/${message.id}`, {withCredentials: true});
  }

  if (message.systemMessage) {
    return <div className="message-item system-message">
      <div className="message-wrap">{message.message}</div>
    </div>;
  } else {
    const outgoing = user.activeProfile.profileId === message.profileId;

    return <div className={`message-item ${outgoing ? 'outgoing-message' : ''} ${message.pending !== undefined && message.pending == true ? 'pending-message' : ''}`}>
      <div className="message-user">
        <figure className="avatar">
          <img src={`${process.env.REACT_APP_API_URL}/image/${message.profile.pictureUid}/thumb`} alt="profile image" />
        </figure>
        <div>
          <h5>{message.profile.name}</h5>
          <div className="time">{Math.abs(new Date(message.date).valueOf() - new Date().valueOf()) > 24 * 60 * 60 * 1000 * 3 ? Intl.DateTimeFormat().format(new Date(message.date)): getRelativeTime(new Date(message.date))}</div>
        </div>
      </div>
      {message.imageUid !== null && !deleted &&
          <figure>
            <img src={`${process.env.REACT_APP_API_URL}/image/${message.imageUid}/thumb.webp`} className="mt-2 img-fluid rounded-3" alt="attachment" />
          </figure>
      }
      <div className="message-wrap position-relative">
        {outgoing && message.pending != true && deleted != true && <BsThreeDots className="message-dots position-absolute font-xl cursor-pointer" onClick={toggleMenu} style={{left: '-40px'}}/>}
        <div className={`dropdown-menu p-0 right-0 rounded-xxl border-0 shadow-lg dots-menu ${showMenu && 'show'}`}
          aria-labelledby="dropdownMenu3">
          <div className="pe-4 card py-2 bg-transparent-card w-100 ps-4 mb-0">
            <a className="font-xsss mb-1 mt-0 fw-700 d-block text-danger cursor-pointer" onClick={deleteMessage}>Delete</a>
          </div>
        </div>
        {deleted ? <i>Deleted Message</i> : message.message}
      </div>
    </div>;
  }
}
