import {AdvertismentCampaign, EarnedProfileBadge} from '@prisma/client';
import {ProfileBadge as BadgeType} from '.prisma/client';
import Tippy from '@tippyjs/react';
import {Icon} from '@iconify/react';
import Button from '../Button';
import {useHistory} from 'react-router-dom';

export default function Campaigns({campaigns}: { campaigns: AdvertismentCampaign[] }) {
  const history = useHistory();
  console.log(campaigns);

  return <>
    <div className="row pt-3">
      <div key="new" className="col-lg-3 cursor-pointer hover-gray mb-3" onClick={() => history.push('/advertiser/campaign/edit')}>
        <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3 h-100 position-relative">
          <div className="row">
            <div className="col-lg-6 ps-lg-5">
              <Icon icon={'line-md:plus'} fontSize={40} className={'position-absolute hover-gray'}
                style={{top: 0, right: 0, left: 0, bottom: 0, margin: 'auto'}}/>
            </div>
          </div>
        </div>
      </div>
      {campaigns.map((campaign) => <div key={campaign.id} className="col-lg-3 mb-3">
        <div className="card w-100 border-0 shadow-none p-3 rounded-xxl bg-lightblue2 mb-3 h-100 hover-gray"
          onClick={() => history.push('/advertiser/campaign/edit/' + campaign.id)}>
          <h4 className="d-block mb-2 text-grey-900 fw-700">{campaign.name}</h4>
          <div className="d-flex flex-row align-items-center text-primary pb-3 pt-2">
            <Icon icon={'jam:birthday-cake'} className={'me-2'} fontSize={30}/>
            <span className="text-dark-white text-dark"><>{new Date(campaign.startDate).toDateString()}</></span>
          </div>
          <div className="d-flex flex-row align-items-center text-primary pb-3">
            <Icon icon={'ri:coins-fill'} className={'me-2'} fontSize={30}/>
            <span className="text-dark-white text-dark"><>{campaign.spent} / {campaign.budget} credits</></span>
          </div>
          <div className="d-flex flex-row align-items-center text-primary pb-0">
            <Icon icon={'material-symbols:query-stats'} className={'me-2'} fontSize={30}/>
            <span className="text-dark-white text-dark"><>{campaign.impressions} impressions</></span>
          </div>
          <div className="row">
            <div className="col-lg-6 ps-lg-5">
              {/* <p className="font-xssss fw-500 text-grey-500 lh-26">{campaign.description}</p>*/}
            </div>
            <div className="col-lg-6">
              {/* <PostCard post={{}}>*/}
            </div>
          </div>
        </div>
      </div>)}
    </div>
  </>;
}
