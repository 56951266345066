import {Component, Fragment} from 'react';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import axios from 'axios';
import useSWR from 'swr';
import Loading from '../components/Loading';
import {useGetAdvertiserAccountQuery, useOpenAccountMutation} from '../store/ads/adsApi';
import PostCard from '../components/PostCard';
import Button from '../components/Button';
import Chart from 'react-apexcharts';
import {Icon} from '@iconify/react';
import Campaigns from '../components/ads/Campaigns';

export default function Advertiser() {
  const {data: account, isLoading} = useGetAdvertiserAccountQuery();
  const [openAccount, {isLoading: creating}] = useOpenAccountMutation();

  if (isLoading) return <Loading/>;

  if (account === null) {
    return (
      <Fragment>
        <Header />
        <Leftnav />
        <Rightchat />

        <div className="main-content bg-white right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                    <div className="row">

                      <div className="col-lg-6 ps-lg-5">
                        <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">Start advertising with Onrain!</h2>
                        <p className="font-xssss fw-500 text-grey-500 lh-26">Open an free onrain advertiser account and start your first campaign for just 1 credit per 1000 impressions!</p>
                        <Button onClick={() => openAccount()} loading={creating} className="btn w200 border-0 bg-primary-gradiant p-3 text-white fw-600 rounded-3 d-inline-block font-xssss">Open Account</Button>
                      </div>
                      <div className="col-lg-6">
                        {/* <PostCard post={{}}>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Popupchat />
        <Appfooter />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Header />
        <Leftnav />
        <Rightchat />

        <div className="main-content bg-white right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-12 flex-row d-flex">
                  <h4 className="font-xl fw-700">Advertisement</h4>
                  <div className="ms-auto card border-0 shadow-none p-2 rounded-xxl bg-greylight d-flex flex-row align-items-center">
                    <Icon icon={'tabler:coins'} className="text-primary font-lg me-2" /> <div className="text-dark text-dark-white fw-700 lh-1">{account.credits}</div>
                  </div>
                </div>
              </div>
              <Campaigns campaigns={account.campaigns} />
            </div>
          </div>
        </div>

        <Popupchat />
        <Appfooter />
      </Fragment>
    );
  }
}

