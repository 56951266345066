import {Community, CommunityMember, CommunityType} from '@prisma/client';
import Button from './Button';
import {useHistory} from 'react-router-dom';
import {useUser} from '../helpers/auth-context';
import {useJoinCommunityMutation, useLeaveCommunityMutation} from '../store/community/communityApi';

export default function CommunityCard({community, membership, requested = false}: {community: Community & { _count: { members: number, posts: number } }, membership?: CommunityMember, requested?: boolean}) {
  const history = useHistory();
  const {reloadUser} = useUser();
  const [joinCommunity, {isLoading: loadingJoin}] = useJoinCommunityMutation();
  const [leaveCommunity, {isLoading: loadingLeave}] = useLeaveCommunityMutation();
  const isLoading = loadingJoin || loadingLeave;


  function join() {
    joinCommunity(community.tag).unwrap()
        .then(() => reloadUser());
  }

  function leave() {
    leaveCommunity(community.tag).unwrap()
        .then(() => reloadUser());
  }

  return (
    <div className="card w-100 shadow-xss rounded-xxl overflow-hidden border-0 mb-3 mt-3 pb-3">
      <div className="card-body position-relative h150 bg-image-cover bg-image-center"
        style={{backgroundImage: community.bannerUid ?
                `url("${process.env.REACT_APP_API_URL}/image/${community.bannerUid}/thumb.webp")` :
                `url("${process.env.REACT_APP_API_URL}/image/${community.imageUid}/thumb.webp")`}}></div>
      <div className="card-body d-block pt-4 text-center">
        <figure className="avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto"><img src={`${process.env.REACT_APP_API_URL}/image/${community.imageUid}/thumb.webp`} alt="avater" className="p-1 bg-card rounded-xl w-100" /></figure>
        <h4 className="font-xs ls-1 fw-700 text-grey-900">{community.name} <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{community.tag}</span></h4>
      </div>
      <div className="card-body d-flex align-items-center ps-4 pe-4 pt-0">
        <h4 className="font-xsssss text-center text-grey-500 fw-600 ms-2 me-2 cursor-pointer" onClick={() => history.push(`/c/${community.tag}/members`)}><b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">{community._count.members}</b> Members</h4>
        <h4 className="font-xsssss text-center text-grey-500 fw-600 ms-2 me-2"><b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">{community._count.posts}</b> Posts</h4>
        {/* <h4 className="font-xsssss text-center text-grey-500 fw-600 ms-2 me-2"><b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">32k </b> Follow</h4>*/}
      </div>
      <div className="card-body d-flex align-items-center justify-content-center ps-4 pe-4 pt-0">
        {(()=> {
          switch (membership?.role) {
            case 'OWNER':
              return <>
                <Button className="p-3 mb-2 mb-lg-0 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3" disabled={true} variant="dark">Leave</Button>
                <Button onClick={() => history.push(`/messages/${community.groupId}`)} className="mb-2 px-2 mb-lg-0 btn-round-lg ms-2 rounded-3" variant="light" size="lg"><i style={{lineHeight: '1.5'}} className="feather-mail font-md"></i></Button>
                <Button className="mb-2 px-2 mb-lg-0 btn-round-lg ms-2 rounded-3" variant="light" size="lg" onClick={() => history.push(`/c/${community.tag}/edit`)}><i style={{lineHeight: '1.5'}} className="feather-edit-3 font-md"></i></Button>
              </>;
            case 'ADMIN':
              return <>
                <Button className="p-3 mb-2 mb-lg-0 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3" variant="dark" loading={isLoading} onClick={leave}>Leave</Button>
                <Button onClick={() => history.push(`/messages/${community.groupId}`)} className="mb-2 px-2 mb-lg-0 btn-round-lg ms-2 rounded-3" variant="light" size="lg"><i style={{lineHeight: '1.5'}} className="feather-mail font-md"></i></Button>
                <Button className="mb-2 px-2 mb-lg-0 btn-round-lg ms-2 rounded-3" variant="light" size="lg" onClick={() => history.push(`/c/${community.tag}/edit`)}><i style={{lineHeight: '1.5'}} className="feather-edit-3 font-md"></i></Button>
              </>;
            case 'MODERATOR':
            case 'MEMBER':
              return <>
                <Button className="p-3 mb-2 mb-lg-0 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3" variant="dark" loading={isLoading} onClick={leave}>Leave</Button>
                <Button onClick={() => history.push(`/messages/${community.groupId}`)} className="mb-2 px-2 mb-lg-0 btn-round-lg ms-2 rounded-3" variant="light" size="lg"><i style={{lineHeight: '1.5'}} className="feather-mail font-md"></i></Button>
              </>;
            default:
              return requested ?
                  <Button className="p-3 text-white fw-700" variant="dark" loading={isLoading} onClick={join}>Cancel Request</Button> :
                  <Button className="p-3 text-white fw-700" variant="success" loading={isLoading} onClick={join}>{community.type !== CommunityType.PUBLIC ? 'Request to ' : ''}Join</Button>;
          }
        })()}
      </div>
    </div>
  );
}
