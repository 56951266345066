import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {AdvertiserAccount, Advertisment, AdvertismentCampaign, Post} from '@prisma/client';

export const adsApi = createApi({
  reducerPath: 'ads/adsApi',
  baseQuery: fetchBaseQuery({baseUrl: process.env.REACT_APP_API_URL + '/sda', credentials: 'include'}),
  tagTypes: ['Account'],
  endpoints: (builder) => ({
    getAdvertiserAccount: builder.query<(AdvertiserAccount & {campaigns: (AdvertismentCampaign & {ads: (Advertisment & {post: Post})[]})[]}) | null, void>({
      query: () => '/account/me',
      providesTags: ['Account'],
    }),
    openAccount: builder.mutation<void, void>({
      query: () => ({
        url: `/account/open`,
        method: 'POST',
      }),
      invalidatesTags: ['Account'],
    }),
    createCampaign: builder.mutation<void, Omit<AdvertismentCampaign, 'id' | 'accountId' | 'startDate' | 'endDate'> & {posts: string[]}>({
      query: (body) => ({
        url: `/campaign/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Account'],
    }),
    patchCampaign: builder.mutation<void, Omit<AdvertismentCampaign, 'accountId' | 'startDate' | 'endDate'> & {posts: string[]}>({
      query: (body) => ({
        url: `/campaign/`+body.id,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Account'],
    }),
  }),
});

export const {
  useGetAdvertiserAccountQuery,
  useOpenAccountMutation,
  useCreateCampaignMutation,
  usePatchCampaignMutation,
} = adsApi;
