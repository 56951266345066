import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import RequireUser from '../components/RequireUser';
import Loading from '../components/Loading';
import Load from '../components/Load';
import Notfound from './Notfound';
import {useUser} from '../helpers/auth-context';
import {Link, useParams} from 'react-router-dom';
import useSWR from 'swr';
import axios from 'axios';
import {useEffect, useRef, useState} from 'react';
import ChatMessage from '../components/messages/ChatMessage';
import {ProfileInfo} from '../api-types';
// @ts-ignore
import cuid from 'cuid';
import RightMessages from '../components/header/RightMessages';
import {getInitials} from '../helpers/utils';
import {BsBell, BsBellSlash} from 'react-icons/bs';
import {AiOutlineLink} from 'react-icons/ai';
import {Button} from 'react-bootstrap';
import {ImCross} from 'react-icons/im';

export type Message = {
  id: string
  date: Date
  groupId: string
  systemMessage: boolean
  profileId: string | null
  userId: string | null
  message: string
  imageUid: string | null
  deleted: boolean
}

const sentRefs = new Set<string>();

export default function Messages() {
  const {user} = useUser();
  const {groupId} = useParams<{groupId: string}>();
  const [messages, setMessages] = useState<(Message & ProfileInfo & {pending? : boolean, error?: string})[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [sendError, setSendError] = useState<string | undefined>(undefined);
  const messageInput = useRef<any>(null);
  const ws = useRef<WebSocket>(null);
  const messageBox = useRef<any>(null);
  const messageRef = useRef<(Message & ProfileInfo & {pending? : boolean, error?: string})[]>(null);
  const [atBottom, setAtBottom] = useState<boolean>(true);
  const [loadingMute, setLoadingMute] = useState<boolean>(false);
  messageRef.current = messages;

  const [connect, setConnect] = useState<number>(0);
  const [photos, setPhotos] = useState([]);
  const [uploading, setUploading] = useState<boolean>(false);

  async function uploadPhoto(e:File) {
    if (uploading) return;
    setUploading(true);
    const formData = new FormData();
    formData.append('images', e, e.name);
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/image/upload/message`, formData, {withCredentials: true});
    if (res.status === 200) {
      setPhotos([res.data.id, ...photos]);
    }
    setUploading(false);
  }

  if (!user) return <RequireUser> </RequireUser>;

  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };
  const {data, error, mutate} = useSWR(`${process.env.REACT_APP_API_URL}/messages/group/${groupId}`, fetcher);

  function more(reset = false) {
    if (!reset && (loading || !hasMore)) return;
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/messages/group/${groupId}/messages?take=20${messages.length > 0 && !reset ? '&last='+messages[messages.length-1].id : ''}`, {withCredentials: true})
        .then((res) => {
          if (res.status === 200) {
            setMessages(reset ? res.data : messages.concat(res.data));
            setHasMore(res.data.length === 20);
          } else {
            setHasMore(false);
          }
          setLoading(false);
        });
  }

  useEffect(() => {
    sentRefs.clear();
    more(true);
    return () => setMessages([]);
  }, [groupId]);

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_API_URL}/messages/group/${groupId}/readall`, {}, {withCredentials: true});
  }, [groupId]);

  useEffect(() => {
    console.log('Attempting to connect to websocket server');
    ws.current = new WebSocket(process.env.REACT_APP_WS_URL);
    ws.current.onopen = () => console.log('Connection to websocket server established');
    ws.current.onclose = () => {
      console.log('Connection to websocket server lost...');
      setTimeout(() => {
        setConnect(connect + 1);
      }, 5000);
    };

    ws.current.onmessage = (e) => {
      const msg = JSON.parse(e.data);
      switch (msg.type) {
        case 'MESSAGE_SENT': {
          const ref = msg.data.ref;
          setMessages(messageRef.current.map((t) => {
            if (t.id === ref) {
              t.pending = false;
            }
            return t;
          }));
          break;
        }
        case 'NEW_MESSAGE': {
          const ref = msg.data.ref;
          if (msg.data.message.groupId !== groupId || sentRefs.has(ref)) return;
          ws.current.send(JSON.stringify({type: 'READ_MESSAGE', data: {
            groupId: groupId,
            messageId: msg.data.message.messageId,
          }}));
          setMessages([msg.data.message, ...messageRef.current]);
        }
      }
    };

    const client = ws.current;
    return () => {
      client.close();
      ws.current = null;
    };
  }, [connect, groupId]);

  if (!data) return <Loading />;
  if (data.status === 404) return <Notfound />;

  const group = data.data;
  console.log(group);

  function mute() {
    if (loadingMute) return;
    setLoadingMute(true);
    axios.patch(`${process.env.REACT_APP_API_URL}/messages/group/${groupId}/mute`, {mute: !group.muted}, {withCredentials: true})
        .then((res) => {
          mutate();
          setLoadingMute(false);
        });
  }


  function sendMessage(message: string) {
    if (sending) return;
    if (message === undefined || message.length < 1) return;
    setSending(true);
    const ref = cuid();
    if (!ws.current) setSendError('Could not reach server');
    ws.current.send(JSON.stringify({type: 'SEND_MESSAGE', data: {
      groupId: group.id,
      message: message,
      image: photos.length > 0 ? photos[0] : undefined,
      reference: ref,
    }}));
    setPhotos([]);
    sentRefs.add(ref);
    if (messageInput.current !== null) {
      messageInput.current.value = '';
    }
    const profile = user.activeProfile.profile;

    const result: Message & ProfileInfo & {pending? : boolean, error?: string} = {
      deleted: false,
      imageUid: photos[0] || null,
      userId: user.id,
      pending: true,
      message: message,
      systemMessage: false,
      date: new Date(),
      groupId: groupId,
      id: ref,
      profileId: profile.id,
      profile: {
        pictureUid: profile.pictureUid,
        name: profile.name,
        tag: profile.tag,
      },
    };
    setMessages([result, ...messages]);
    setSending(false);
  }

  if (atBottom && messageBox.current !== null) messageBox.current.scrollTop = 0;
  const other = group.type === 'PERSONAL' ? group.members.find((t) => t.profileId !== user.activeProfile.profileId) : undefined;
  const blocked = group.type === 'PERSONAL' && user.activeProfile?.profile.blocked.some((t) => t.tag === other.profile.tag);

  return (
    <RequireUser>
      <Header />
      <RightMessages/>

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0" style={{maxWidth: '100%'}}>
            <div className="row">
              <div className="col-lg-12 position-relative">
                <div className="chat-wrapper pt-0 w-100 position-relative bg-white theme-dark-bg">
                  <div className="px-3 top-0 w-100 position-absolute" style={{zIndex: 9}}>
                    <div className="w-100 p-3 card d-flex align-items-center flex-row">
                      {group.imageUid !== null ? <img
                        src={`${process.env.REACT_APP_API_URL}/image/${group.imageUid}/thumb`} alt="user"
                        className="w40 me-3 rounded-circle"/> :
                          <span className="btn-round-mds bg-primary-gradiant me-3 ls-3 text-white font-xssss fw-700">{getInitials(group.name)}</span>}<h5
                        className="font-xs text-grey-900 text-grey-900 mb-0 mt-0 fw-500"><strong>{group.name} </strong>{group.subName && <span className="text-grey-600 font-xsss">{group.subName}</span>}<span
                          className="d-block text-grey-500 font-xssss fw-600 mb-0 mt-0 0l-auto">{group.description}</span>
                      </h5><a className="font-md ms-auto text-grey-500 cursor-pointer" onClick={mute} title={group.muted ? 'Unmute this message group' : 'Mute this message group'}>{group.muted ? <BsBellSlash/> :<BsBell/>}</a><Link to={`${groupId}/options`} className="ms-3 font-xs"><i className="ti-more-alt text-grey-500"></i></Link>
                    </div>
                  </div>
                  <div className="chat-body p-3 position-absolute bottom-0 w-100 ">
                    <div ref={messageBox} onScroll={(e) => {
                      if (messageBox.current.scrollTop < -100 && atBottom) setAtBottom(false);
                      else if (messageBox.current.scrollTop > -100 && !atBottom) setAtBottom(true);

                      if (messageBox.current.scrollHeight + messageBox.current.scrollTop - messageBox.current.offsetHeight < 500) {
                        more();
                      }
                    }} className="messages-content scroll-bar d-flex flex-column-reverse pb-5">
                      {messages.map((msg) => <ChatMessage key={msg.id} message={msg} group={group}/>)}
                      {hasMore &&
                          <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                            <div className="stage">
                              <div className="dot-typing"></div>
                            </div>
                          </div>
                      }
                      <div style={{height: '150px', fontSize: 60, visibility: 'hidden'}}>t</div>
                    </div>
                  </div>
                </div>
                <input disabled={blocked} type="file" name="upload-photo" id="upload-photo" accept="image/*"
                  className="input-file"
                  onChange={(e) => {
                    uploadPhoto(e.target.files[0]);
                  }}/>
                <div className="chat-bottom dark-bg p-3 shadow-none theme-dark-bg flex-column" style={{width: '98%'}}>
                  <form className="chat-form" onSubmit={(e) => {
                    sendMessage(messageInput.current?.value);
                    e.preventDefault();
                  }}>
                    <div className="position-absolute" style={{top: '-140px'}}>
                      {photos.map((photo) => <div className="position-relative photos-container">
                        <img src={`${process.env.REACT_APP_API_URL}/image/${photo}/thumb.webp`} className="photo" alt="uploaded photo"/>
                        <Button className="position-absolute remove-button"
                          onClick={() => setPhotos((photos) => photos.filter((p) => p !== photo))}>
                          <ImCross className="remove-button-icon"/></Button>
                      </div>)}
                    </div>
                    <div className="button bg-grey float-left"
                      onClick={() => {
                        document.getElementById('upload-photo')?.click();
                      }}
                    ><AiOutlineLink className="font-xl"/></div>
                    <input maxLength={2000} ref={messageInput} disabled={sending || blocked} className="form-group" type="text" placeholder={blocked ? 'You cannot send messages to someone you blocked' : 'Start typing..'} />
                    <button className="bg-current" type="submit" disabled={sending || blocked}><i className="ti-arrow-right text-white"></i></button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />

    </RequireUser>
  );
}
