import React, {Component} from 'react';
import {IoBusinessOutline} from 'react-icons/io5';


export default function ProfileDetail({profile, own=false}) {
  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-block p-4">
        <h4 className="fw-700 mb-3 font-xsss text-grey-900">About</h4>
        <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">{profile.bio !== '' ? profile.bio : <i>Profile has no biography.</i>}</p>
      </div>
      {own && profile.type === 'PERSONAL' &&
                        <div className="card-body d-flex border-top-xs">
                          <i className="feather-user text-grey-500 me-3 font-lg"></i>
                          <h4 className="fw-700 text-grey-900 font-xssss mt-0">Personal <span
                            className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">This profile is your personal profile!</span>
                          </h4>
                        </div>
      }
      {own && profile.type === 'ORGANISATION' &&
                    <div className="card-body d-flex border-top-xs">
                      <IoBusinessOutline className="feather-archive text-grey-500 me-3 font-lg" />
                      <h4 className="fw-700 text-grey-900 font-xssss mt-0">Organisation <span
                        className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">This profile is for your organisation!</span>
                      </h4>
                    </div>
      }
      {own && profile.visibility === 'PUBLIC' &&
                    <div className="card-body d-flex pt-0">
                      <i className="feather-eye text-grey-500 me-3 font-lg"></i>
                      <h4 className="fw-700 text-grey-900 font-xssss mt-0">Public <span
                        className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Everyone can see your posts</span>
                      </h4>
                    </div>
      }
      {own && profile.visibility === 'PRIVATE' &&
                    <div className="card-body d-flex pt-0">
                      <i className="feather-eye-off text-grey-500 me-3 font-lg"></i>
                      <h4 className="fw-700 text-grey-900 font-xssss mt-0">Private <span
                        className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Only people following you can see your posts</span>
                      </h4>
                    </div>
      }
      {/* <div className="card-body d-flex pt-0">
                    <i className="feather-map-pin text-grey-500 me-3 font-lg"></i>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">Flodia, Austia </h4>
                </div>
                <div className="card-body d-flex pt-0">
                    <i className="feather-users text-grey-500 me-3 font-lg"></i>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">Genarel Group</h4>
                </div>*/}
    </div>
  );
}
