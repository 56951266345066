import Header from '../components/Header';
import {Link} from 'react-router-dom';

export default function NotAllowed() {
  return (<><Header/>
    <div className="main-content pt-0 bg-white ps-0 pe-0">
      <div className="container">
        <div className="row justify-content-center">
          <div
            className="col-lg-6 col-md-8 text-center default-page vh-100 align-items-center d-flex">
            <div className="card bg-transparent-card border-0 text-center d-block p-0">
              <h1 className="fw-700 text-grey-900 display3-size display4-md-size">Oops! You cannot view this page!</h1>
              <p className="text-grey-500 font-xsss">You are not allowed to view this page!</p>
              <Link to="/"
                className="p-3 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-3 text-uppercase ls-3">Home</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}
