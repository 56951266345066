import {Fragment} from 'react';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import {useUser} from '../helpers/auth-context';
import axios from 'axios';
import RightMessages from '../components/header/RightMessages';
import useSWR from 'swr';
import {Link} from 'react-router-dom';


function Name({uuid}: {uuid:string}) {
  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: false, validateStatus: () => true});
  };

  const {data} = useSWR(`https://playerdb.co/api/player/minecraft/${uuid}`, fetcher);

  console.log(data);

  if (data === undefined) return uuid;

  return data.data?.data?.player?.username || uuid;
}

export default function AccountList() {
  const {user} = useUser();


  return (

    <Fragment>
      <Header/>
      <Leftnav/>
      <RightMessages />

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">

                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="row mb-lg-5 mb-4">
                          <div className="col align-items-center d-flex">
                            <h4 className="font-xxl fw-700 mont-font m-0 font-md-xs">Linked Accounts</h4>
                          </div>
                          <div className="col-auto align-items-center d-flex">
                            <Link to="/settings/accounts/link" className="fw-700 m-0 ">Link New Account</Link>
                          </div>
                        </div>
                      </div>
                      <div
                        className="profile-wrapper p-3 w-100 position-relative scroll-bar">
                        <ul className="profile-list">
                          {user.accounts.map((account, index) => (

                            <li key={index}>

                              <div
                                className={`profile-entry rounded-3`}>
                                <div className="profile-user" style={{flex: '0 0 60%'}}>
                                  <img
                                    src={`https://crafatar.com/renders/head/${account.uuid}`}
                                    alt="user"
                                    className="w35 me-2"/>
                                  <div style={{lineHeight: '12px'}}>
                                    <h6 className="font-xssss text-grey-900 mb-0 mt-0 fw-700"><Name uuid={account.uuid}/></h6>
                                    <span
                                      className="font-xssss text-grey-900">{account.phonenumber}</span>
                                  </div>
                                </div>
                                <div
                                  className="email-subject text-grey-900 text-dark fw-600 font-xssss">
                                  {account.verified? <span
                                    className="bg-success text-white font-xsssss rounded-max ms-2 ls-3 fw-600 py-1 px-2 mt-0">Verified</span>:
                                      <span
                                        className="bg-warning text-white font-xsssss rounded-max ms-2 ls-3 fw-600 py-1 px-2 mt-0">Pending</span>}
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <Popupchat/>
      <Appfooter/>
    </Fragment>);
}
