import useSWR from 'swr';
import axios from 'axios';
import {getInitials, getRelativeTime} from '../../helpers/utils';
import {Link} from 'react-router-dom';
import {BsPatchCheckFill} from 'react-icons/bs';

function cutOff(str: string, length: number) {
  return str.length > length ? str.substring(0, length) + '...' : str;
}

const featuredTypes = {
  'HYPETEAM_ACES': {
    backgroundColor: '#E2546C',
    classes: 'bg-aces',
    tagDescription: (posts) => `${posts} posts | by Onrain Aces`,
  },
  'HYPETEAM_ANTS': {
    backgroundColor: '#4e22a1',
    classes: 'bg-ants',
    tagDescription: (posts) => `${posts} posts | by Onrain Ants`,
  },
  'OFFICIAL': {
    backgroundColor: '#B04A45',
    classes: 'bg-official',
    tagDescription: (posts) => `${posts} posts | Featured Tag`,
  },
  'NONE': {
    backgroundColor: '#B04A45',
    classes: 'bg-greylight bg-themedark-light',
    tagDescription: (posts) => `${posts} posts`,
  },
};

export default function TrendingTags() {
  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };
  const {data, error} = useSWR(`${process.env.REACT_APP_API_URL}/feeds/trending-tags`, fetcher);

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 pb-3">
      <div className="card-body d-flex align-items-center p-4">
        <h4 className="fw-700 mb-0 font-xssss text-grey-900">Trending Tags</h4>
      </div>
      {data !== undefined ? data.data.map((tag, index) => {
        const feature = featuredTypes[tag.featured || 'NONE'];

        return (
          <div className="wrap" key={index}>
            <Link to={`/tag/${tag.tag}`} className={`${feature.classes} card-body d-flex ps-2 pe-2 pb-0 bor-0 p-2 ms-3 me-3 rounded-3 mb-2`}>
              <span className="btn-round-sm me-2 tag-icon text-white fw-600 position-relative" style={{backgroundColor: feature.backgroundColor}}><span className="position-absolute">#</span></span>
              <h4 className="fw-700 text-grey-900 font-xssss mt-1 position-relative">#{cutOff(tag.tag, 16)}
                <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">{feature.tagDescription(tag._count)}</span></h4>
            </Link>
          </div>
        );
      }) : <></>}
    </div>
  );
}

