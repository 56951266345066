import axios from 'axios';

const units = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: 24 * 60 * 60 * 1000 * 365/12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};

const rtf = new Intl.RelativeTimeFormat(undefined, {numeric: 'auto'});

export const getRelativeTime = (d1: Date, d2 :Date = new Date()) : string => {
  const elapsed = d1.valueOf() - d2.valueOf();

  // "Math.abs" accounts for both "past" & "future" scenarios
  for (const u in units) {
    if (Math.abs(elapsed) > units[u] || u == 'second') {
      return rtf.format(Math.round(elapsed/units[u]), u as Intl.RelativeTimeFormatUnit);
    }
  }
};

export const getInitials = (name: string) : string =>{
  const split = name.split(' ');
  let res = split[0].charAt(0).toUpperCase();
  if (split.length > 1) res+=split[1].charAt(0).toUpperCase();
  return res;
};

export const fetcher = async (url: string) => {
  return await axios.get(url, {withCredentials: true}).then((res) => res.data);
};

export function downloadURI(url: string, fileName: string) {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function reduxRTKInfiniteScroll<T>({equalFn = ((a, b) => a===b)}: {equalFn?: (a:T, b:T) => boolean}) {
  return {
    serializeQueryArgs: ({endpointName}) => {
      return endpointName;
    },
    merge: (currentCache:T[], newItems:T[]) => {
      currentCache.push(...newItems.filter((newItem) => !currentCache.some((cacheItem) => equalFn(newItem, cacheItem))));
    },
    forceRefetch({currentArg, previousArg}) {
      return currentArg !== previousArg;
    },
  };
}
