import {Fragment} from 'react';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import {useUser} from '../helpers/auth-context';
import axios from 'axios';
import RightMessages from '../components/header/RightMessages';
import useSWR from 'swr';
import {Link, useHistory} from 'react-router-dom';
import Button from '../components/Button';


export default function ExternalAccounts() {
  const {user} = useUser();

  return (

    <Fragment>
      <Header/>
      <Leftnav/>
      <RightMessages />

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">

                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="row mb-lg-5 mb-4">
                          <div className="col align-items-center d-flex">
                            <h4 className="font-xxl fw-700 mont-font m-0 font-md-xs">External Accounts</h4>
                          </div>
                        </div>
                      </div>
                      <div
                        className="profile-wrapper p-3 w-100 position-relative scroll-bar">
                        <ul className="profile-list">
                          <li>
                            <div
                              className={`profile-entry rounded-3`}>
                              <div className="profile-user" style={{flex: '0 0 40%'}}>
                                <img
                                  src={`/assets/images/discord-mark-white.svg`}
                                  alt="user"
                                  className="w35 p-1 rounded me-2" style={{backgroundColor: '#5865F2', height: 35}}/>
                                <div style={{lineHeight: '12px'}}>
                                  <h6 className="font-xssss text-grey-900 mb-0 mt-0 fw-700">Discord</h6>
                                </div>
                              </div>
                              <div
                                className="email-subject text-grey-900 text-dark fw-600 font-xssss" style={{flex: '0 0 20%'}}>
                                {user.discordId ? <span
                                  className="bg-success text-white font-xsssss rounded-max ms-2 ls-3 fw-600 py-1 px-2 mt-0">Enabled</span>:
                                    <span
                                      className="bg-danger text-white font-xsssss rounded-max ls-3 fw-600 py-1 px-2 mt-0">Disabled</span>}
                              </div>
                              <div
                                className="email-subject text-grey-900 text-dark fw-600 font-xssss ms-auto">
                                {!user.discordId && <Button onClick={() => {
                                  window.location.href = process.env.REACT_APP_API_URL + '/auth/discord';
                                }}>Link Account</Button>}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <Popupchat/>
      <Appfooter/>
    </Fragment>);
}
